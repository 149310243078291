<template>
  <section class="w-560px"
    data-testid="summary-table">
    <h3 v-if="heading"
      class="text-align-center mt-1 mb-3">
      {{ heading }}
    </h3>
    <div v-for="(item, index) in summaryItems"
      :key="index">
      <template v-if="Array.isArray(item.value)">
        <div class="summary-section-head"
          @click="toggleExpansion(index)">
          {{ $t(item.label) }}
          <i class="fas fa-chevron-up expand-arrow"
            :class="isItemExpanded(index) ? '' : 'active'"
            aria-hidden="true" />
        </div>

        <el-collapse-transition>
          <div v-if="isItemExpanded(index)">
            <div v-for="(subItem, subIndex) in item.value"
              :key="subIndex">
              <summary-row :label="subItem.label"
                :label-tooltip="subItem.labelTooltip"
                :value="{ value:subItem.value, status:subItem.status,checkMark:subItem.checkMark }"
                :value-tooltip="subItem.valueTooltip" />
            </div>
          </div>
        </el-collapse-transition>
      </template>
      <summary-row v-else
        :label="item.label"
        :label-tooltip="item.labelTooltip"
        :value="{ value:item.value, status:item.status ,checkMark:item.checkMark}"
        :value-tooltip="item.valueTooltip" />
    </div>

    <hr class="mt-3">
    <el-row v-if="showMonthlyRate"
      class="monthly-rate"
      :gutter="8">
      <el-col :span="12">
        {{ $t('pricebook.monthly-rate') }}:
      </el-col>
      <el-col v-if="monthlyRate"
        :span="12">
        <span data-name="monthlyRate">{{ monthlyRate }}</span>
        <p class="exclude-tax">
          {{ $t('pricebook.excludes-tax') }}
        </p>
      </el-col>
      <el-col v-else
        :span="12">
        {{ $t('pricebook.contact-sales') }}
      </el-col>
    </el-row>
    <hr v-if="showMonthlyRate">

    <slot name="footer" />
  </section>
</template>

<script>
import SummaryRow from '@/components/ui-components/SummaryRow.vue'
import {
  SERVICE_STATUS_UNKNOWN,
  SERVICE_STATUS_UP,
  SERVICE_STATUS_DOWN,
} from '@/Globals.js'

export default {
  name: 'SummaryContent',

  components: {
    'summary-row': SummaryRow,
  },

  props: {
    /**
     * The heading for the page
     */
    heading: {
      type: String,
      required: false,
      default: '',
    },
    /**
     * An array of objects with the following attributes:
     *
     * - label: String, Required. The key that is used for this row. If the value is a string or number, tt will
     *   be displayed on the left side. If the value is an array is will be used as the heading for the
     *   expandable section housing the details from the array. The key will be run through the
     *   translation first, so it's fine to use internationalised keys.
     * - labelTooltip: String, Optional. If you supply this, it will be run through the translation system and
     *   displayed as a tooltip for the label. It will be attached to a help icon after the text.
     * - value: String | Number | Array, Optional. You must supply either a status or a value. The supplied value will
     *   be displayed as is without running it through translation. If an Array is supplied, then it is presented as an
     *   expandable section with the label as the toggle label, and the array contents as the items (same format as the
     *   top level array).
     * - status: String, Optional. You must supply either a status or a value. It must be one of the valid
     *   statuses (SERVICE_STATUS_UNKNOWN, SERVICE_STATUS_UP, or SERVICE_STATUS_DOWN from globals). If the status
     *   is supplied, the system will display a status icon instead of the value.
     * - valueTooltip: String, Optional.
     */
    summaryItems: {
      type: Array,
      required: true,
      validator: value => {
        for (const item of value) {
          if (!item.label) {
            return false
          }
          // OK to pass in an empty string or 0 for the value
          if (!(item.value || item.value === '' || item.value === 0) && !item.status && !item.checkMark) {
            return false
          }
          if (item.status && ![SERVICE_STATUS_UNKNOWN, SERVICE_STATUS_UP, SERVICE_STATUS_DOWN].includes(item.status)) {
            return false
          }
        }
        return true
      },
    },
    /**
     * Whether to show the monthly rate section at the bottom of the summary.
     */
    showMonthlyRate: {
      type: Boolean,
      default: false,
    },
    /**
     * If you supply this and showMonthlyRate is true, it will be displayed as supplied.
     *
     * The passed in value should be the entire formatted string e.g. $2,000.00 AUD.
     *
     * If you say to display the monthly rate and don't supply a value, it will display the
     * standard message to contact sales for custom pricing.
     */
    monthlyRate: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      displayedSections: [],
    }
  },

  methods: {
    toggleExpansion(index) {
      const itemLocation = this.displayedSections.indexOf(index)
      if (itemLocation === -1) {
        this.displayedSections.push(index)
      } else {
        this.displayedSections.splice(itemLocation, 1)
      }
    },
    isItemExpanded(index) {
      return this.displayedSections.includes(index)
    },
  },
}
</script>

<style lang="scss" scoped>
.monthly-rate {
  padding-block: 2rem;
  color: var(--color-black);
  font-weight: 300;
  white-space: normal;
  word-break: break-word;
  .el-col:first-of-type {
    font-weight: 500;
    text-align: right;
  }
}

.summary-section-head {
  padding: 0.5rem;
  text-align: center;
  background: var(--button-default-background-color);
  border: var(--border-base);
  border-radius: var(--button-border-radius);
  border-color: var(--button-default-border-color);
  color: var(--button-default-font-color);
  margin: 1rem;
  transition: 0.1s;
  cursor: pointer;
  &:hover {
    color: var(--color-primary);
    border-color: var(--color-primary-light-7);
    background-color: var(--color-primary-light-9);
  }
  &:focus {
    outline: none;
  }
}
.expand-arrow {
  transition: transform 0.3s;
  &.active {
    transform: rotate(180deg);
  }
  &:focus {
    outline: none;
  }
}

.exclude-tax {
  margin: 0;
  font-size: 0.9rem;
}
</style>
