<template>
  <section class="mveVendorsForm"
    data-name="mve-vendors-form">
    <el-form ref="mveVendorsForm"
      :model="mveConfig"
      label-width="400px"
      :rules="validationRules"
      @validate="checkValidation">
      <template v-for="(formatter, index) in fields">
        <!-- Text Input -->
        <el-form-item v-if="['text', 'password'].includes(formatter.type)"
          :key="`${formatter.key}-${index}`"
          :prop="formatter.key"
          :required="formatter.required">
          <template #label>
            {{ formatter.label }}
            <el-tooltip v-if="formatter.tooltip"
              placement="top"
              :data-testid="`${formatter.key}Tooltip`"
              :open-delay="500">
              <template #content>
                <p class="tooltip-p"
                  v-html="formatter.tooltip" /> <!-- eslint-disable-line vue/no-v-html -->
                <p v-if="!disabledFeatures.knowledgeBase"
                  class="tooltip-p">
                  {{ $t('general.details-help') }}
                  <a href="https://docs.megaport.com/mve/"
                    target="_blank"
                    rel="noopener">
                    <el-button size="mini">{{ $t('general.documentation') }}</el-button>
                  </a>
                </p>
              </template>
              <i class="fas fa-question-circle color-info popover-info-icon"
                aria-hidden="true" />
            </el-tooltip>
          </template>
          <el-input :value="mveConfig[formatter.key]"
            :data-name="formatter.path"
            :data-demo="generateDemoValue(formatter)"
            :data-testid="formatter.key"
            :placeholder="formatter.placeholder || ''"
            :show-password="formatter.type === 'password' ? true : null"
            :auto-complete="formatter.type === 'password' ? 'new-password' : 'off'"
            @input="handleInputChange($event, formatter.key)" />
        </el-form-item>

        <!-- Select -->
        <el-form-item v-if="formatter.type === 'select'"
          :key="`${formatter.key}-${index}`"
          :prop="formatter.key"
          :required="formatter.required">
          <template #label>
            {{ formatter.label }}
            <el-tooltip v-if="formatter.tooltip"
              placement="top"
              :data-testid="`${formatter.key}Tooltip`"
              :open-delay="500">
              <template #content>
                <div v-if="formatter.tooltip.includes('</li>')"
                  class="tooltip-p"
                  v-html="formatter.tooltip" /> <!-- eslint-disable-line vue/no-v-html -->
                <p v-else
                  class="tooltip-p">
                  {{ $t(formatter.tooltip) }}
                </p>
              </template>
              <i class="fas fa-question-circle color-info popover-info-icon"
                aria-hidden="true" />
            </el-tooltip>
          </template>
          <el-select :value="mveConfig[formatter.key]"
            :data-testid="formatter.key"
            :name="formatter.path"
            class="mve-select"
            @change="handleInputChange($event, formatter.key)">
            <el-option v-for="option in formatter.options()"
              :key="option.value"
              :label="option.label"
              :value="option.value"
              :data-option="option.value"
              :data-testid="`${formatter.key}Item option${option.value}`"
              class="mve-size" />
          </el-select>
          <p v-if="formatter.sizeInfo && formatter.sizeInfo()"
            class="mve-size-info">
            {{ formatter.sizeInfo() }}
          </p>
        </el-form-item>

        <!--Upload -->
        <el-form-item v-if="formatter.type === 'upload'"
          :key="`${formatter.key}-${index}`"
          :prop="formatter.key"
          :required="formatter.required">
          <template #label>
            {{ formatter.label }}
            <el-tooltip v-if="formatter.tooltip"
              placement="top"
              :open-delay="500">
              <template #content>
                <p class="tooltip-p"
                  v-html="formatter.tooltip" /> <!-- eslint-disable-line vue/no-v-html -->
                <p v-if="!disabledFeatures.knowledgeBase"
                  class="tooltip-p">
                  {{ $t('general.details-help') }}
                  <a href="https://docs.megaport.com/mve/"
                    target="_blank"
                    rel="noopener">
                    <el-button size="mini">{{ $t('general.documentation') }}</el-button>
                  </a>
                </p>
              </template>
            </el-tooltip>
          </template>
          <el-upload :data-testid="formatter.key"
            action=""
            :multiple="formatter.multiple"
            :show-file-list="formatter.showFileList"
            :drag="formatter.drag"
            :data-name="formatter.path"
            :before-upload="file => beforeFileUpload(file, formatter)">
            <el-button type="primary"
              plain
              data-name="choose-file-btn">
              {{ $t('general.choose-file') }}
            </el-button>
            <template #tip>
              <span v-if="!mveConfig[formatter.upload.file.key] && !fileName"
                class="fine-print ml-1">{{ $t('validations.no-file-selected') }}</span>
              <div v-else
                class="m-1"
                role="presentation">
                <p class="fine-print">
                  <i class="far fa-file"
                    aria-hidden="true" /> {{ mveConfig[formatter.upload.file.key] || fileName }}
                  <i class="far fa-check-circle color-success"
                    aria-hidden="true" />
                </p>
              </div>
              <div v-if="formatter.upload.help"
                role="presentation">
                <p class="fine-print">
                  {{ typeof formatter.upload.help === 'function' ? formatter.upload.help() : formatter.upload.help }}
                </p>
              </div>
            </template>
          </el-upload>
        </el-form-item>

        <!-- Text Area -->
        <el-form-item v-if="formatter.type === 'textarea'"
          :key="`${formatter.key}-${index}`"
          :prop="formatter.key"
          :required="formatter.required">
          <template #label>
            {{ formatter.label }}
            <el-tooltip v-if="formatter.tooltip"
              placement="top"
              :open-delay="500">
              <template #content>
                <p class="tooltip-p"
                  v-html="formatter.tooltip" /> <!-- eslint-disable-line vue/no-v-html -->
                <p v-if="!disabledFeatures.knowledgeBase"
                  class="tooltip-p">
                  {{ $t('general.details-help') }}
                  <a href="https://docs.megaport.com/mve/"
                    target="_blank"
                    rel="noopener">
                    <el-button size="mini">{{ $t('general.documentation') }}</el-button>
                  </a>
                </p>
              </template>
              <i class="fas fa-question-circle color-info popover-info-icon"
                aria-hidden="true" />
            </el-tooltip>
          </template>
          <el-input type="textarea"
            :rows="8"
            :value="mveConfig[formatter.key]"
            data-name="mve-name"
            :data-demo="generateDemoValue(formatter)"
            :data-testid="formatter.key"
            @input="handleInputChange($event, formatter.key)" />
          <div v-if="formatter.help"
            role="presentation">
            <p class="fine-print">
              {{ formatter.help }}
            </p>
          </div>
        </el-form-item>

        <!-- Multi-text -->
        <el-form-item v-if="formatter.type === 'multi-text'"
          :key="`${formatter.key}-${index}`"
          class="multi"
          :show-message="false"
          :prop="formatter.key">
          <template #label>
            {{ formatter.label }}
          </template>
          <div v-for="(item, i) in mveConfig[formatter.key]"
            :key="i"
            class="mb-1">
            <el-input v-model="item[formatter.itemKey]"
              :class="{ 'has-error' : multiTextFieldHasError(formatter.key, i)}"
              :data-name="`${formatter.path}-${i}`"
              :data-demo="generateDemoValue(formatter, i)"
              :data-testid="formatter.key"
              :placeholder="formatter.placeholder || ''">
              <template slot="prepend">
                {{ i }}
              </template>
              <el-button v-if="i >= formatter.minimumItems"
                slot="append"
                icon="el-icon-delete"
                @click="mveConfig[formatter.key].splice(i, 1)" />
            </el-input>
            <div v-if="multiTextFieldHasError(formatter.key, i)"
              class="color-danger line-height-1-2em">
              {{ mveConfigValidation[formatter.key].error[i] }}
            </div>
          </div>
          <el-button v-if="showMultiTextFieldAddButton(formatter)"
            variant="primary"
            class="full-width"
            :data-testid="`${formatter.key}-add-button`"
            icon="el-icon-circle-plus"
            @click="multiTextFieldAdd(formatter)">
            Add
          </el-button>
        </el-form-item>

        <!-- Checkbox -->
        <el-form-item v-if="formatter.type === 'checkbox'"
          :key="`${formatter.key}-${index}`"
          :prop="formatter.key"
          :required="formatter.required">
          <template #label>
            {{ formatter.label }}
            <el-tooltip v-if="formatter.tooltip"
              placement="top"
              :open-delay="500">
              <template #content>
                <p class="tooltip-p"
                  v-html="formatter.tooltip" /> <!-- eslint-disable-line vue/no-v-html -->
                <p v-if="!disabledFeatures.knowledgeBase"
                  class="tooltip-p">
                  {{ $t('general.details-help') }}
                  <a href="https://docs.megaport.com/mve/"
                    target="_blank"
                    rel="noopener">
                    <el-button size="mini">{{ $t('general.documentation') }}</el-button>
                  </a>
                </p>
              </template>
              <i class="fas fa-question-circle color-info popover-info-icon"
                aria-hidden="true" />
            </el-tooltip>
          </template>
          <el-checkbox :value="mveConfig[formatter.key]"
            :name="formatter.key"
            :data-name="formatter.path"
            :data-testid="formatter.key"
            @change="handleInputChange($event, formatter.key)" />
        </el-form-item>

        <!-- Term -->
        <term-select v-if="formatter.type === 'term'"
          :key="`${formatter.key}-${index}`"
          :value="mveConfig[formatter.key]"
          :term-prices="termPrices"
          :loading-term-prices="loadingTermPrices"
          :prop="formatter.key"
          @input="handleInputChange($event, formatter.key)" />
      </template>
    </el-form>
  </section>
</template>

<script>
import { get } from 'lodash'
import { mapGetters } from 'vuex'

import { DEFAULT_TERM_PRICES } from '@/Globals.js'
import { scopedPriceBook } from '@/utils/priceBook.js'

import TermSelect from '@/components/ui-components/TermSelect.vue'

const FILE_MAX_KB = 24

export default {
  name: 'MveVendorsForm',

  components: {
    'term-select': TermSelect,
  },

  inject: ['disabledFeatures'],

  props: {
    fields: {
      type: Array,
      required: true,
      default: () => [],
    },
    currentData: {
      type: Object,
      required: true,
      default: () => {
        // empty function is intentional
      },
    },
  },

  data() {
    return {
      mveConfig: null,
      mveConfigValidation: null,
      isValidForm: false,
      termPrices: DEFAULT_TERM_PRICES,
      loadingTermPrices: false,
    }
  },

  computed: {
    ...mapGetters('Services', ['getLocationById']),

    validationRules() {
      return this.fields.reduce((acc, field) => {
        if (field.validations) {
          return {
            ...acc,
            [field.key]: field.validations,
          }
        }

        return acc
      }, {})
    },
    keyPaths() {
      const keyPaths = []
      const configPaths = this.fields.map(field => {
        const { key, path, upload = null } = field

        if (upload) {
          keyPaths.push(upload.file)
        }

        return {
          key,
          path,
        }
      })

      return [
        ...keyPaths,
        ...configPaths,
      ]
    },
    vendorFieldKeys() {
      return this.fields.map(item => item.key)
    },
    // Check the file recovered from shopping cart saved as configured
    fileName() {
      return this.currentData.config?.custom_properties?.filename
    },
    currentLocation() {
      return this.getLocationById(this.currentData.locationId)
    },
  },

  watch: {
    // With the introduction of Appliance Mode (ENG-19216),
    // our fields no longer depend only on vendor selection but are also mutually dependant,
    // so we need to set up our validation from scratch every time they change.
    fields: {
      immediate: true,
      deep: true,
      handler() {
        this.generateMveConfigToValidate()
        this.generateMveConfigValidation()
      },
    },
    'currentData.vendorConfig.productSize'() {
      this.updateTermPrices()
    },
  },

  mounted() {
    // Validate all vendor fields each time the component is mounted
    this.$refs.mveVendorsForm.validateField(this.vendorFieldKeys)
    this.$nextTick(() => {
      this.$refs.mveVendorsForm.clearValidate()
    })
  },

  created() {
    this.generateMveConfigToValidate()
    this.generateMveConfigValidation()
    this.updateTermPrices()
  },

  methods: {
    scopedPriceBook,

    showMultiTextFieldAddButton(field) {
      return this.mveConfig[field.key]?.length < field.maxItems
    },
    multiTextFieldHasError(field, index) {
      return this.mveConfigValidation?.[field].error?.[index]
    },
    multiTextFieldAdd(field) {
      this.mveConfig[field.key].push({ [field.itemKey]: '' })

      this.$nextTick(() => {
        const vnics = this.$el.querySelectorAll(`[data-name^='${field.key}']`)
        let lastAdded = vnics[vnics.length - 1]
        lastAdded.focus()
      })
    },
    checkValidation(field, isValid, error) {
      // For some reason when this validation runs,
      // the fields it checks are not up to date, so ignore non-relevant fields.
      if (!this.vendorFieldKeys.includes(field)) return

      this.mveConfigValidation = {
        ...this.mveConfigValidation,
        [field]: { isValid, error },
      }

      this.isValidForm = Object.values(this.mveConfigValidation).every(item => !!item.isValid)
      this.$emit('isValid', this.isValidForm)
    },
    handleInputChange(value, key) {
      this.mveConfig = {
        ...this.mveConfig,
        [key]: value,
      }

      this.emitUpdate(key)
    },
    beforeFileUpload(file, config) {
      // Handle the error display
      const handleError = (key, title = '', message = '') => {
        const props = {
          title,
          message,
          duration: 5000,
        }
        this.$notify.error(props)
        this.$nextTick(() => {
          this.$refs.mveVendorsForm.validateField(key)
          this.$refs.mveVendorsForm.clearValidate(key)
        })
      }

      const { key, upload } = config
      const { key: fileKey } = upload.file
      this.$refs.mveVendorsForm.clearValidate(key)

      const reader = new FileReader()

      reader.onload = () => {
        let encoded = null
        try {
          encoded = btoa(reader.result)
        } catch (error) {
          handleError(
            key,
            this.$t('validations.wrong-file-type'),
            this.$t('validations.select-text-file')
          )
          this.handleInputChange(null, key)
          this.handleInputChange(null, fileKey)

          return false
        }

        if (encoded.length > FILE_MAX_KB * 1024) {
          handleError(
            key,
            this.$t('validations.file-too-long'),
            this.$t('validations.file-max-length', { max: FILE_MAX_KB })
          )
          this.handleInputChange(null, key)
          this.handleInputChange(null, fileKey)

          return false
        }

        this.handleInputChange(encoded, key)
        this.handleInputChange(file.name, fileKey)

        this.$nextTick(() => {
          this.$refs.mveVendorsForm.validateField(key)
        })
      }

      reader.onerror = () => {
        handleError(
          this.$t('validations.file-read-error'),
          reader.error
        )
        this.handleInputChange(null, key)
        this.handleInputChange(null, fileKey)
      }

      reader.readAsText(file)

      return false
    },
    validate() {
      this.$refs.mveVendorsForm.validate(valid => {
        this.isValidForm = valid
        if (!valid) {
          const props = {
            title: this.$t('validations.failed'),
            message: this.$t('validations.correct-issues'),
            duration: 3000,
          }
          this.$notify.error(props)

          return false
        }
      })
      // Emit the form validation upstream
      this.$emit('isValid', this.isValidForm)
    },
    emitUpdate(key) {
      const result = this.keyPaths.find(item => item.key === key)
      const { path } = result
      const value = this.mveConfig[key]
      this.$emit('update', {
        value,
        path,
      })
    },
    generateMveConfigToValidate() {
      this.mveConfig = this.fields.reduce((acc, field) => {
        let currentField = {
          [field.key]: get(this.currentData, field.path),
        }

        // Consider the nested upload file config
        if (field.upload) {
          currentField = {
            ...currentField,
            [field.upload.file.key]: get(this.currentData, field.upload.file.path),
          }
        }

        return {
          ...acc,
          ...currentField,
        }
      }, {})
    },
    generateMveConfigValidation() {
      this.mveConfigValidation = this.fields.reduce((acc, field) => {
        if (field.validations) {
          return {
            ...acc,
            [field.key]: false,
          }
        }

        return acc
      }, {})
    },
    generateDemoValue(field, index = 0) {
      if (field.key === 'productName') {
        const { name: locationName, metro } = this.currentLocation
        const vendor = this.currentData.vendorConfig._vendor

        return `${locationName} @ ${metro} - ${vendor} ${this.G_PRODUCT_TYPE_MVE}`
      }

      if (field.key === 'vnics') {
        return `vNIC Description ${index}`
      }

      return field.demo || ''
    },
    async updateTermPrices() {
      if (!this.currentData.vendorConfig?.productSize) {
        this.termPrices = DEFAULT_TERM_PRICES
      } else {
        this.loadingTermPrices = true

        try {
          this.termPrices = await this.scopedPriceBook()
            .mveTerms(
              this.currentData.locationId,
              this.currentData.vendorConfig._vendor,
              this.currentData.vendorConfig.productSize,
              this.currentData.productUid
            )
        } catch (error) {
          // Error handled by the pricebook module.
          this.termPrices = DEFAULT_TERM_PRICES
        } finally {
          this.loadingTermPrices = false
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.fine-print {
  font-size: 1.3rem;
  line-height: 1.375em;
  font-weight: 300;
  color: var(--color-text-regular);
  max-width: fit-content;
}
.mve-select {
  min-width: 350px;
  width: 100%;
}
.mveVendorsForm::v-deep label.el-form-item__label {
  white-space: nowrap;
}
.mve-size-info {
  margin-block-start: 1rem;
  margin-block-end: 0;
  padding-inline: 1rem;
  color: var(--color-success);
  line-height: initial;
}
</style>
