<template>
  <section v-loading.fullscreen="!servicesReady || !locationsReady"
    :element-loading-text="$t('services.loading-services')">
    <template v-if="servicesReady && locationsReady && checkMveAvailable()">
      <mve-design v-if="designing" />
      <mve-deployed v-else />
    </template>
  </section>
</template>

<script>
import MveDesign from '@/views/MveDesign.vue'
import MveDeployed from '@/views/MveDeployed.vue'
import { mapGetters, mapState } from 'vuex'
import { resolveServicesPage } from '@/utils/MapDataUtils.js'

export default {
  name: 'MVEPage',

  components: {
    'mve-design': MveDesign,
    'mve-deployed': MveDeployed,
  },

  computed: {
    ...mapState({
      servicesReady: state => state.Services.servicesReady,
      locationsReady: state => state.Services.locationsReady,
    }),
    ...mapGetters('MVE', ['getMVE']),
    ...mapGetters('Auth', ['isPartnerAccount']),
    ...mapGetters('ApplicationContext', ['companyContextLoading']),
    allowNotify() {
      return !this.isPartnerAccount && !this.companyContextLoading
    },
    creating() {
      return !this.$route.params.productUid
    },
    designing() {
      if (this.creating) {
        return true
      }
      const mve = this.getMVE(this.$route.params.productUid)
      if (mve.provisioningStatus === this.G_PROVISIONING_DESIGN) {
        return true
      }

      return false
    },
  },

  methods: {
    /**
     * This is run after the servicesReady and locationsReady are true, and makes
     * sure that the MVE is actually available. Side effect: if not available, it
     * will redirect the user to the services page.
     */
    checkMveAvailable() {
      const productUid = this.$route.params.productUid
      if (!productUid) {
        return true
      }
      const mve = this.getMVE(productUid)
      if (!mve) {
        const props = {
          title: this.$t('general.type-not-found', { type: this.$t('productNames.mve') }),
          message: this.$t('general.type-not-found-redirect', { type: this.$t('productNames.mve') }),
          type: 'error',
          duration: 3000,
        }
        this.allowNotify && this.$notify(props)
        this.$router.push(resolveServicesPage())
        return false
      }
      return true
    },
  },
}
</script>
