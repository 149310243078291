<template>
  <service-graph :service="currentData" />
</template>

<script>
import ServiceGraphComponent from '@/components/graphs/ServiceGraph.vue'

export default {
  name: 'DeployedUsageMVE',

  components: {
    'service-graph': ServiceGraphComponent,
  },

  props: {
    currentData: {
      type: Object,
      required: true,
    },
  },
}
</script>
