<template>
  <el-row :gutter="8">
    <!-- Label Column -->
    <el-col :span="12"
      :data-testid="`${kebabCase(label)}-label`">
      <!-- Label -->
      {{ $t(label) }}<template v-if="labelTooltip">
        <!-- Label Tooltip -->
        <el-tooltip :content="$t(labelTooltip)"
          placement="top"
          :open-delay="500"
          :data-testid="`${kebabCase(label)}-label-tooltip`">
          <i class="fas fa-question-circle color-info popover-info-icon"
            aria-hidden="true" />
        </el-tooltip>
      </template>:
    </el-col>
    <!-- Value Column -->
    <el-col :span="12"
      :data-name="label"
      :data-testid="`${kebabCase(label)}-value`">
      <!-- Value Tooltip -->
      <el-tooltip v-if="valueTooltip"
        :content="$t(valueTooltip)"
        placement="top"
        :open-delay="500"
        :data-testid="`${kebabCase(label)}-value-tooltip`">
        <!-- Status Icon -->
        <div v-if="isStatus"
          class="service-status-icon mt-0-25"
          :class="value.status"
          :data-testid="`${kebabCase(label)}-status-icon`" />
        <!-- Value -->
        <div v-else>
          {{ value.value }}
        </div>
      </el-tooltip>
      <template v-else>
        <!-- Status Icon -->
        <div v-if="isStatus"
          class="service-status-icon mt-0-25"
          :class="value.status"
          :data-testid="`${kebabCase(label)}-status-icon`" />
        <!-- Checkmark -->
        <div v-if="isCheckMark">
          <div v-if="isCheckMark">
            <i class="far fa-check color-primary icon-size"
              aria-hidden="true"
              :data-testid="`${kebabCase(label)}-checkmark`" />
          </div>
        </div>
        <!-- Diversity Zone -->
        <template v-else-if="label === $t('services.diversity-zone')">
          <i class="far fa-dot-circle mr-0-5"
            :class="`diversity-color-${value.value}`"
            aria-hidden="true"
            :data-testid="`diversity-${value.value}-icon`" />
          <span>{{ $t(`general.${value.value}`) }}</span>
        </template>
        <!-- Value -->
        <div v-else>
          {{ value.value }}
        </div>
      </template>
    </el-col>
  </el-row>
</template>

<script>
import { kebabCase } from 'lodash'

import {
  SERVICE_STATUS_UNKNOWN,
  SERVICE_STATUS_UP,
  SERVICE_STATUS_DOWN,
} from '@/Globals.js'

export default {
  name: 'SummaryRow',
  props: {
    /**
     * The label for the data row. This is run through translation.
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * If you want a tooltip on the label.
     */
    labelTooltip: {
      type: String,
      required: false,
      default: null,
    },
    /**
     * The value to be displayed. An object with either a status or a primitive value supplied.
     * If the status is supplied, it must be one of the valid statuses, and that will be used
     * instead of the value.
     */
    value: {
      type: Object,
      required: true,
      validator: value => {
        if (Object.hasOwn(value, 'status') &&
          [SERVICE_STATUS_UNKNOWN, SERVICE_STATUS_UP, SERVICE_STATUS_DOWN].includes(value.status)) {
          return true
        }
        if (Object.hasOwn(value, 'value')) {
          return true
        }

        return false
      },
    },
    /**
     * The tooltip to display on the value. This will be run through translation. Note
     * that unlike the labelTooltip which gets an icon, this is just a tooltip on the value.
     */
    valueTooltip: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    isStatus() {
      return !!this.value.status
    },
    isCheckMark() {
      return this.value.checkMark
    },
  },
  methods: {
    kebabCase,
  },
}
</script>

<style lang="scss" scoped>
.el-row {
  color: var(--color-text-regular);
  font-weight: 300;
  padding: 1rem;
  white-space: normal;
  word-break: break-all;
  .el-col:first-of-type {
    font-weight: 500;
    text-align: right;
  }
}
.icon-size {
  font-size: 2rem;
}
</style>
