// Encrypt as per algorithm at https://akkadia.org/drepper/SHA-crypt.txt

// Note that this explicitly doesn't support sha512.

import Crypto from 'crypto'

/**
 *
 * @param {string} password The unencrypted password
 * @param {number} rounds (optional) The number of rounds, 1000 to 999999999
 * @param {string} saltArg (optional) The salt value to use
 *
 * @returns string The correctly formatted encrypted value
 * @throws Error if there are any incorrect params
 */
const encrypt = (password, rounds = 5000, saltArg) => {
  let salt = saltArg
  if (!saltArg) {
    salt = Crypto.randomBytes(4).toString('hex')
  }
  if (!(/^[./0-9A-Za-z]{0,16}$/.test(salt))) {
    throw new Error('Salt must contain only characters from ./0-9A-Za-z and be 0 to 16 characters (inclusive) long')
  }

  if (rounds < 1000 || rounds > 999999999) {
    throw new Error('Rounds must be between 1000 and 999999999')
  }

  // Steps 1-12
  const digestA = generateDigestA(password, salt)

  // Steps 13-15
  const hashDP = Crypto.createHash('sha256')
  const passwordByteLength = Buffer.byteLength(password)
  for (let i = 0; i < passwordByteLength; i++) {
    hashDP.update(password)
  }
  const digestDP = hashDP.digest()

  // Step 16a
  const p = Buffer.alloc(passwordByteLength)
  for (let offset = 0; offset + 32 < passwordByteLength; offset += 32) {
    p.set(digestDP, offset)
  }

  // Step 16b
  const remainder = passwordByteLength % 32
  p.set(digestDP.subarray(0, remainder), passwordByteLength - remainder)

  // Steps 17-19
  const hashDS = Crypto.createHash('sha256')
  const step18 = 16 + digestA[0]
  for (let i = 0; i < step18; i++) {
    hashDS.update(salt)
  }
  const digestDS = hashDS.digest()

  // Step 20
  const s = Buffer.alloc(salt.length)

  // Step 20a
  // Isn't this step redundant? The salt string doesn't have 32 or 64 bytes. It's truncated to 16 characters
  const saltByteLength = Buffer.byteLength(salt)
  for (let offset = 0; offset + 32 < saltByteLength; offset += 32) {
    s.set(digestDS, offset)
  }

  // Step 20b
  const saltRemainder = saltByteLength % 32
  s.set(digestDS.subarray(0, saltRemainder), saltByteLength - saltRemainder)

  // Step 21
  const roundsArray = Array(rounds).fill(0)
  const digestC = roundsArray.reduce((acc, curr, idx) => {
    const hashC = Crypto.createHash('sha256')

    // Steps b-c
    if (idx % 2 === 0) {
      hashC.update(acc)
    } else {
      hashC.update(p)
    }

    // Step d
    if (idx % 3 !== 0) {
      hashC.update(s)
    }

    // Step e
    if (idx % 7 !== 0) {
      hashC.update(p)
    }

    // Steps f-g
    if (idx % 2 !== 0) {
      hashC.update(acc)
    } else {
      hashC.update(p)
    }

    return hashC.digest()
  }, digestA)

  const checksum = base64Encode(digestC)

  return rounds === 5000 ? `$5$${salt}$${checksum}` : `$5$rounds=${rounds}$${salt}$${checksum}`
}

/**
 * Handles the first few stages of the algorithm separately since there are no dependencies
 * on anything else.
 *
 * @param {string} password The plain text password
 * @param {string} salt The salt to use
 * @returns Buffer
 */
const generateDigestA = (password, salt) => {
  // Steps 1-8
  const hashA = Crypto.createHash('sha256')
  hashA.update(`${password}${salt}`)

  const hashB = Crypto.createHash('sha256')
  hashB.update(`${password}${salt}${password}`)

  const digestB = hashB.digest()

  // Step 9
  const passwordByteLength = Buffer.byteLength(password)

  for (let offset = 0; offset + 32 < passwordByteLength; offset += 32) {
    hashA.update(digestB)
  }

  // Step 10
  const remainder = passwordByteLength % 32
  hashA.update(digestB.subarray(0, remainder))

  // Step 11
  const binArray = [...passwordByteLength.toString(2)].reverse()
  for (const item of binArray) {
    hashA.update(item === '0' ? password : digestB)
  }

  // Step 12
  return hashA.digest()
}

/**
 * Use the special mapping of values to generate a specially formatted base 64 string.
 *
 * @param {Buffer} digest
 * @returns string
 */
const base64Encode = digest => {
  // Note that the documentation hase the byte numbers backwards so we do them the other way here
  const shuffleMap = [
    20, // 1
    10,
    0,
    11, // 2
    1,
    21,
    2, // 3
    22,
    12,
    23, // 4
    13,
    3,
    14, // 5
    4,
    24,
    5, // 6
    25,
    15,
    26, // 7
    16,
    6,
    17, // 8
    7,
    27,
    8, // 9
    28,
    18,
    29, // 10
    19,
    9,
    30, // 11
    31,
    // No final mapping needed since it's 32 bit
  ]

  let hash = ''
  for (let idx = 0; idx < digest.length; idx += 3) {
    const buf = Buffer.alloc(3)
    buf[0] = digest[shuffleMap[idx]]
    buf[1] = digest[shuffleMap[idx + 1]]
    buf[2] = digest[shuffleMap[idx + 2]]

    hash += bufferToBase64(buf)
  }

  // Adjust hash length by stripping trailing zeroes induced by base64-encoding
  return hash.slice(0, digest.length === 32 ? -1 : -2)
}

/**
 * Encode buffer to base64 using our dictionary
 *
 * @param {Buffer} buf Buffer to be encoded to base 64
 * @returns string
 */
const bufferToBase64 = buf => {
  const dictionary =
  './0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'

  const first = buf[0] & parseInt('00111111', 2)
  const second =
    ((buf[0] & parseInt('11000000', 2)) >>> 6) |
    ((buf[1] & parseInt('00001111', 2)) << 2)
  const third =
    ((buf[1] & parseInt('11110000', 2)) >>> 4) |
    ((buf[2] & parseInt('00000011', 2)) << 4)
  const fourth = (buf[2] & parseInt('11111100', 2)) >>> 2
  return `${dictionary.charAt(first)}${dictionary.charAt(second)}${dictionary.charAt(third)}${dictionary.charAt(fourth)}`
}

/**
 * Check whether the supplied password matches the hashed value.
 *
 * @param {string} password The plain text password
 * @param {string} hash The hash as previously generated
 * @returns boolean
 */
const verify = (password, hash) => {
  const hashArgs = hash.split('$')
  let rounds = 5000
  let salt = undefined
  if (hashArgs.length === 4) {
    // Short form
    salt = hashArgs[2]
  } else {
    // Long form with the rounds
    rounds = Number.parseInt(hashArgs[2].split('=')[1])
    salt = hashArgs[3]
  }
  const computedHash = encrypt(password, rounds, salt)
  return computedHash === hash
}

export {
  encrypt,
  verify,
}
