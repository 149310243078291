<template>
  <summary-content v-loading="loading"
    :heading="$t('general.type-details', { type: $t('productNames.mve') })"
    :summary-items="items"
    :show-monthly-rate="false" />
</template>

<script>
import SummaryContent from '@/components/ui-components/SummaryContent.vue'
import { mapGetters } from 'vuex'
import {
  SERVICE_STATUS_UNKNOWN,
  SERVICE_STATUS_UP,
  SERVICE_STATUS_DOWN,
} from '@/Globals.js'
import captureSentryError from '@/utils/CaptureSentryError.js'
import sdk from '@megaport/api-sdk'

export default {
  name: 'DeployedDetailsMve',

  components: {
    'summary-content': SummaryContent,
  },

  data() {
    return {
      createdByUser: null,
      loading: false,
    }
  },

  computed: {
    ...mapGetters('Auth', ['hasFeatureFlag']),
    ...mapGetters('MVE', ['getMVE']),
    rawMve() {
      return this.getMVE(this.$route.params.productUid)
    },
    showVNics() {
      return this.rawMve.vnics?.length > 0
    },
    mveSize() {
      return this.rawMve.mveLabel
    },
    items() {
      const status = {
        status: SERVICE_STATUS_UNKNOWN,
        tooltip: this.$t('general.status-unknown'),
      }

      if (typeof this.rawMve.up === 'boolean') {
        status.status = this.rawMve.up ? SERVICE_STATUS_UP : SERVICE_STATUS_DOWN
        status.tooltip = this.rawMve.up ? this.$t('services.up') : `${this.$t('services.down')} ${this.$t('services.check-logs')}`
      }

      return [
        { label: 'services.service-id', value: `#${this.rawMve._subUid}` },
        { label: 'services.provisioning-status', value: this.rawMve.provisioningStatus },
        { label: 'general.status', status: status.status, valueTooltip: status.tooltip },
        { label: 'general.size', value: this.mveSize },
        { label: 'general.term', value: this.minimumTerm },
        ...(this.createdByUser ? [{ label: 'general.created-by', value: this.createdByUser.name, valueTooltip: this.createdByUser.email }] : []),
        ...(this.rawMve.costCentre ? [{ label: 'services.invoice-reference', value: this.rawMve.costCentre }] : []),
        ...(this.showVNics ? [{ label: 'connections.virtual-interfaces-vnics', value: this.rawMve.vnics.map((vnic, index) => ({ label: `vNIC-${index}`, value: vnic.description || this.$t('connections.no-description') })) }] : []),
      ]
    },
    minimumTerm() {
      const term = this.rawMve.contractTermMonths
      return term === 1 ? this.$t('port-term.no-minimum-term') : this.$tc('general.count-months', term, { count: term })
    },
  },

  created() {
    if (this.rawMve.createdBy) {
      this.loadEmploymentData()
    }
  },

  methods: {
    loadEmploymentData() {
      this.loading = true
      sdk.instance
        .employment()
        .get()
        .then(data => {
          this.createdByUser = data.find(user => user.personUid === this.rawMve.createdBy)
        })
        .catch(e => {
          captureSentryError(e)
        }).finally(() => {
          this.loading = false
        })
    },
  },
}
</script>
