<template>
  <section>
    <h3 class="text-align-center mt-1 mb-3">
      {{ $t('services.service-logs') }}
    </h3>
    <service-logs :service-id="$route.params.productUid" />
  </section>
</template>

<script>
import ServiceLogsComponent from '@/components/ServiceLogs.vue'

export default {
  name: 'DeployedLogsMve',

  components: {
    'service-logs': ServiceLogsComponent,
  },
}
</script>
