<template>
  <div>
    <!-- Port Terms -->
    <port-term v-if="showTermPanel"
      :visible.sync="showTermPanel"
      :port="rawMve" />

    <el-form ref="inputForm"
      v-loading="saving"
      :model="formData"
      :rules="formRules"
      :disabled="!editable"
      label-width="200px">
      <h3 class="text-align-center mt-1 mb-3">
        {{ $t('general.type-configuration', { product: $t('productNames.mve') }) }}
      </h3>

      <el-form-item :label="$t('general.type-name', { type: $t('productNames.mve') })"
        prop="productName">
        <el-input v-model="formData.productName"
          data-name="mve-name"
          data-demo="Example MVE"
          name="productName" />
      </el-form-item>

      <!-- Partner Deals -->
      <el-form-item v-if="showDeals"
        prop="dealUid"
        :label="$t('general.partner-deals')">
        <deal-select :form-data="formData"
          :deals="deals"
          :service-data="currentData"
          @updateDealUid="value => formData.dealUid = value" />
      </el-form-item>

      <el-form-item prop="costCentre">
        <template #label>
          {{ $t('services.invoice-reference') }}
          <el-tooltip placement="top"
            :open-delay="500">
            <template #content>
              <p class="tooltip-p">
                {{ $t('services.invoice-reference-explanation') }}
              </p>
              <p v-if="!disabledFeatures.knowledgeBase"
                class="tooltip-p">
                {{ $t('general.details-help') }}
                <a href="https://docs.megaport.com/mve/"
                  target="_blank"
                  rel="noopener">
                  <el-button size="mini">{{ $t('general.documentation') }}</el-button>
                </a>
              </p>
            </template>
            <i class="fas fa-question-circle color-info popover-info-icon"
              aria-hidden="true" />
          </el-tooltip>
        </template>
        <el-input v-model="formData.costCentre"
          data-name="cost-centre"
          name="costCentre" />
      </el-form-item>

      <!-- Contract Terms / Subscription -->
      <template v-if="rawMve.contractEndDate || rawMve.contractTermMonths">
        <el-form-item :label="$t(isPartnerVantage ? 'partner-vantage.subscription' : 'ports.terms')">
          <template v-if="showTerm">
            <p v-if="rawMve.contractEndDate && rawMve.provisioningStatus === G_PROVISIONING_LIVE"
              class="m-0">
              <strong class="pr-1">{{ $t('general.end-date') }}: </strong>
              {{ formatDate(rawMve.contractEndDate) }}
            </p>
            <p v-else
              class="m-0">
              <strong class="pr-1">{{ $t('general.end-date') }}: </strong>
              {{ $t('ports.end-date-display', { productName }) }}
            </p>
          </template>
          <p v-if="rawMve.contractTermMonths"
            class="m-0">
            <strong class="pr-1">{{ $t('general.term') }}: </strong>
            {{ termDisplay }}
          </p>
          <template v-if="canChangePortTerm">
            <el-button class="mr-1"
              data-name="change-term"
              @click="showTermPanel = true">
              {{ $t(isPartnerVantage ? 'partner-vantage.change-subscription' : 'ports.change-term') }}
            </el-button>
            <a v-if="!disabledFeatures.knowledgeBase && !isPartnerVantage"
              href="https://docs.megaport.com/finance/mve-pricing/"
              target="_blank"
              rel="noopener"
              class="d-block my-0">
              {{ $t('ports.term-info', { productName }) }}
            </a>
          </template>
        </el-form-item>
      </template>
      <div v-if="editable"
        class="text-align-right"
        role="presentation">
        <el-button :disabled="!isDirty"
          data-testid="revert-port"
          @click="revert">
          {{ $t('general.revert') }}
        </el-button>
        <el-button :disabled="!isDirty"
          data-testid="save-port"
          type="primary"
          @click="save">
          {{ $t('general.save') }}
        </el-button>
      </div>
      <hr>
    </el-form>
  </div>
</template>

<script>
// External Tools
import { mapGetters } from 'vuex'
import { DateTime } from 'luxon'
// Internal Tools
import sdk from '@megaport/api-sdk'
import captureSentryError from '@/utils/CaptureSentryError.js'
import { convertProductType } from '@/helpers'
// Components
import PortTermComponent from '@/components/PortTerm.vue'
import DealSelect from '@/components/DealSelect.vue'

export default {
  name: 'DeployedConfigureMve',

  components: {
    'port-term': PortTermComponent,
    'deal-select': DealSelect,
  },

  inject: ['disabledFeatures', 'isFeatureEnabled'],

  props: {
    currentData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      showTermPanel: false,
      formData: {
        productUid: this.$route.params.productUid,
        productName: this.currentData.productName,
        costCentre: this.currentData.costCentre || '',
        dealUid: this.currentData.dealUid,
      },
      formRules: {
        productName: { required: true, message: this.$t('validations.required', { thing: this.$t('general.name') }), trigger: 'blur' },
        costCentre: { min: 0, max: 255, message: this.$tc('validations.max-length', 255, { max: 255 }) },
      },
      saving: false,
      deals: [],

    }
  },

  computed: {
    ...mapGetters('MVE', ['getMVE']),
    ...mapGetters('Auth', ['hasAuth', 'hasFeatureFlag', 'isPartnerVantage', 'isManagedAccount']),
    rawMve() {
      return this.getMVE(this.$route.params.productUid)
    },
    isDirty() {
      // Work with the existing costCentre value and if there isn't one, use the form's default value
      const existingCostCentre = this.rawMve.costCentre || ''
      // Work with the existing dealUid value and if there isn't one, use the form's default value
      const existingDealUid = this.rawMve.dealUid || 'None'

      const configurationTabValidation =
        this.formData.productName !== this.rawMve.productName ||
        this.formData.costCentre !== existingCostCentre ||
        this.formData.dealUid !== existingDealUid

      return configurationTabValidation
    },
    editable() {
      return this.hasAuth('modify_service') && !this.rawMve.locked && !this.rawMve.adminLocked
    },
    showDeals() {
      // Hide if the vantage partner & vantage managed account flags aren't present
      if (!this.isFeatureEnabled('VANTAGE_PARTNER') && !this.isFeatureEnabled('VANTAGE_MANAGED_ACCOUNT')) {
        return false
      }
      // Show if managed account
      return this.isManagedAccount
    },
    showTerm() {
      if (
        ![
          this.G_PROVISIONING_LIVE,
          this.G_PROVISIONING_CANCELLED,
          this.G_PROVISIONING_CANCELLED_PARENT,
          this.G_PROVISIONING_CONFIGURED,
          this.G_PROVISIONING_DEPLOYABLE,
        ].includes(this.rawMve.provisioningStatus)
      ) {
        return false
      }
      return this.rawMve.contractTermMonths > 1
    },
    termDisplay() {
      if (
        ![
          this.G_PROVISIONING_LIVE,
          this.G_PROVISIONING_CANCELLED,
          this.G_PROVISIONING_CANCELLED_PARENT,
          this.G_PROVISIONING_CONFIGURED,
          this.G_PROVISIONING_DEPLOYABLE,
        ].includes(this.rawMve.provisioningStatus)
      ) {
        return this.$t('ports.contract-terms')
      }
      if (this.showTerm) {
        return this.$tc('general.count-months', this.rawMve.contractTermMonths, { count: this.rawMve.contractTermMonths })
      }

      if (this.rawMve.productType === this.G_PRODUCT_TYPE_MVE) {
        return this.$t(this.isPartnerVantage ? 'general.na' : 'port-term.no-minimum-term')
      }

      return this.$t('ports.monthly')
    },
    canChangePortTerm() {
      const allowedStatuses = [
        this.G_PROVISIONING_LIVE,
        this.G_PROVISIONING_CONFIGURED,
        this.G_PROVISIONING_DEPLOYABLE,
        this.G_PROVISIONING_CANCELLED,
        this.G_PROVISIONING_CANCELLED_PARENT,
      ]

      return allowedStatuses.includes(this.currentData.provisioningStatus)
    },
    productName() {
      return convertProductType(this.rawMve.productType)
    },
  },

  created() {
    this.fetchDeals()
  },

  methods: {
    emitUpdate(key, value) {
      this.$emit('update', { key, value })
    },
    formatDate(date) {
      return DateTime.fromMillis(date).toFormat('y-LL-dd')
    },
    validate() {
      return new Promise(resolve => {
        this.$refs.inputForm.validate(valid => {
          if (!valid) {
            const props = {
              title: this.$t('validations.failed'),
              message: this.$t('validations.correct-issues'),
              duration: 3000,
            }
            this.$notify.error(props)

            return resolve(false)
          }
          return resolve(true)
        })
      })
    },
    async save() {
      if (!this.editable) return false

      if (!await this.validate()) return false

      // Update the currentData object with the new saved values
      this.emitUpdate('productName', this.formData.productName)
      this.emitUpdate('costCentre', this.formData.costCentre)
      if (this.showDeals) this.emitUpdate('dealUid', this.formData.dealUid)

      this.saving = true

      await this.$store.dispatch('MVE/saveConfigured', {
        mveData: this.formData,
      })

      this.saving = false
    },
    revert() {
      this.formData = {
        productUid: this.$route.params.productUid,
        productName: this.currentData.productName,
        costCentre: this.currentData.costCentre || '',
        dealUid: this.currentData.dealUid,
      },

      this.validate()
    },
    fetchDeals() {
      this.saving = true
      const companyUid = this.$store.state.Company.data.companyUid || ''
      let defaultDeal = {
        entityUid: 'None',
        dealId: '',
        opportunityName: 'None',
      }
      sdk.instance
        .partnerVantage()
        .deals(companyUid, { includeInvalidDeals: true })
        .then(res => {
          if (res.length) {
            const isStandardDeal = !res.find(deal => deal.entityUid === this.currentData.dealUid)
            // if the dealUid does not match other deal Uid then, it is a standard deal
            if (isStandardDeal) {
              defaultDeal.entityUid = this.currentData.dealUid
            }
            this.deals = [
              defaultDeal,
              ...res,
            ]
            // sort in ascending by deal id
            this.deals = this.deals.sort((a, b) => a.dealId.toUpperCase().localeCompare(b.dealId.toUpperCase()))
          } else {
            this.deals = [defaultDeal]
            this.formData.dealUid = 'None'
          }
        })
        .catch(e => captureSentryError(e))
        .finally(() => {
          this.saving = false
        })
    },
  },
}
</script>
