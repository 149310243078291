<template>
  <el-form ref="locationsForm"
    :model="formData"
    :rules="formRules">
    <el-form-item :label="$t('general.select-type-location', { type: $t('productNames.mve') })"
      prop="locationId"
      name="locationId">
      <mu-item-selector :items="mveLocations"
        :selected-id="formData.locationId"
        :sort-string-function="sortLocationString"
        :to-string-function="locationToString"
        id-attribute="id"
        country-data="country"
        table-max-height="450"
        class="location-select-holder"
        data-testid="location-select-holder"
        @select="updateLocationId">
        <template #content>
          <diversity-filter v-model="diversityZone"
            @input="resetLocation" />
        </template>

        <template #cell="{data}">
          <!-- Location logo -->
          <img :src="`https://media.megaport.com/datacentre/${data.dc.id}/62x30.png`"
            :alt="$t('images.datacentre-logo')"
            class="mr-1 datacentre-logo"
            @error="setFallbackImage($event, 'https://media.megaport.com/datacentre/fallback/MissingDC.svg')">
          <!-- Name and address -->
          <div>
            <p class="location-header my-0"
              :data-service-name="data.name">
              {{ data.name }}
            </p>
            <p class="my-0">
              {{ data.address.city }}, {{ data.country }}
              <span v-if="data.status === 'Deployment'"
                class="fs-1-2rem">{{ $t('general.pre-order') }}</span>
            </p>
          </div>
          <!-- Diversity icon -->
          <div v-if="manuallySelectedDiversityZone"
            class="ml-auto">
            <i class="far fa-dot-circle diversity-zone-icon"
              :class="`diversity-color-${diversityZone}`"
              aria-hidden="true" />
          </div>
        </template>
      </mu-item-selector>
    </el-form-item>
  </el-form>
</template>

<script>
import { mapGetters } from 'vuex'

import { setFallbackImage } from '@/utils/fallbackImage'
import { locationToString, sortLocationString } from '@/helpers'
import { G_DIVERSITY_ZONE_RED, G_DIVERSITY_ZONE_BLUE } from '@/Globals'

import DiversityFilter from '@/components/ui-components/DiversityFilter.vue'

export default {
  name: 'MveConfigureLocation',

  components: {
    'diversity-filter': DiversityFilter,
  },

  props: {
    currentData: {
      type: Object,
      required: true,
    },
  },

  emits: ['update'],

  data() {
    return {
      formData: {
        locationId: null,
      },
      formRules: {
        locationId: {
          required: true,
          message: this.$t('validations.required', { thing: this.$t('general.location') }),
          trigger: 'none',
        },
      },
      diversityZone: this.currentData.config.diversityZone,
    }
  },

  computed: {
    ...mapGetters('MVE', ['getMVE', 'getMVEVendors', 'getAvailableMveLocations']),
    manuallySelectedDiversityZone() {
      return [G_DIVERSITY_ZONE_RED, G_DIVERSITY_ZONE_BLUE].includes(this.diversityZone)
    },
    /**
     * Filter list of MVE locations by selected diversity zone
     */
    mveLocations() {
      return this.getAvailableMveLocations.filter(location => {
        if (this.manuallySelectedDiversityZone) {
          return location.diversityZones?.mve?.some(zone => zone.name === this.diversityZone)
        }

        return true
      })
    },
  },

  created() {
    this.setLocationId()
  },

  updated() {
    this.formData.locationId = this.currentData.locationId
  },

  methods: {
    setFallbackImage,
    locationToString,
    sortLocationString,
    setLocationId() {
      if (this.$route.params.productUid) {
        const mve = this.getMVE(this.$route.params.productUid)
        const available = this.mveLocations.find(loc => loc.id === mve.locationId)
        if (available) {
          this.formData.locationId = mve.locationId
        } else {
          this.emitUpdate('locationId', null)
          this.$nextTick(() => this.validate())
        }
      } else {
        this.formData.locationId = this.currentData.locationId
      }
    },
    updateLocationId({ value }) {
      if (value !== this.formData.locationId) {
        this.formData.locationId = value
        this.emitUpdate('locationId', value)
        this.validate()
      }
    },
    emitUpdate(keyPath, value) {
      this.$emit('update', { keyPath, value })
    },
    validate() {
      this.$refs.locationsForm && this.$refs.locationsForm.validate(valid => {
        if (!valid) {
          const props = {
            title: this.$t('validations.failed'),
            message: this.$t('validations.correct-issues'),
            duration: 3000,
          }
          this.$notify.error(props)
          return false
        }
      })
    },
    /** Reset location when changing diversity zone filter */
    resetLocation() {
      this.emitUpdate('config.diversityZone', this.diversityZone)
      this.formData.locationId = null
      this.emitUpdate('locationId', null)
    },
  },
}
</script>

<style lang="scss" scoped>
.location-select-holder {
  min-width: 450px;
}
.selectable-item-cell .location-header {
  font-weight: 700;
}
.is-error .location-select-holder::v-deep .item-select-holder {
  border-color: var(--color-danger);
}
.is-success .location-select-holder::v-deep .item-select-holder {
  border-color: var(--color-success);
}
.diversity-zone-icon {
  font-size: 2rem;
}
</style>
