<template>
  <section>
    <!-- Header with title, steps, next and previous buttons -->
    <header class="process-steps-header"
      :class="{ 'sticky-top': !layout.integratedHeader }">
      <h3 class="text-align-center">
        {{ $t(creating ? 'general.new-type' : 'general.edit-configured-type', { type: $t('productNames.mve') }) }}
      </h3>

      <process-steps :steps="steps"
        :selected-step.sync="selectedStep"
        display-complete-as-success
        @validateStep="validateCurrentStep"
        @update:selectedStep="selectedStep === 1 ? calculateDiversity() : undefined" />
    </header>

    <!-- Content portion of the page -->
    <section class="p-20px flex-column flex-align-center">
      <!-- Information about the MVE that is being configured -->
      <end-details-card left-icon-id="MVE"
        :detail-lines="detailLines"
        :right-image-src="selectedLocation ? `https://media.megaport.com/datacentre/${selectedLocation.dc.id}/62x30.png` : ''"
        :diversity-zone="mveData.config.diversityZone" />

      <price-box v-if="showPriceBox"
        :monthly-rate="price.monthlyRate"
        :currency="price.currency" />

      <!-- Details -->
      <el-card shadow="never"
        class="content-card"
        :class="currentDetailComponent.name">
        <!-- The components that edit and display the content go in here -->
        <component :is="currentDetailComponent"
          :key="`vendor-image-${ids.image}`"
          ref="currentDetailComponent"
          :current-data="mveData"
          :image-data="mveImageData"
          :fields="filteredFields"
          :mve-size-label="selectedMveLabel"
          @update="updateData"
          @update:image="updateImageData"
          @isValid="handleValidation" />

        <!-- Action buttons -->
        <footer class="flex-row-centered justify-content-end mt-2">
          <el-button data-name="close-button"
            @click="$router.push(resolveServicesPage())">
            {{ $t('general.cancel') }}
          </el-button>
          <el-button v-if="selectedStep > 0"
            type="primary"
            data-name="back-button"
            @click="gotoStep(selectedStep - 1)">
            <i class="fas fa-arrow-circle-left"
              aria-hidden="true" /> {{ $t('general.back') }}
          </el-button>
          <el-button v-if="selectedStep < steps.length - 1"
            type="primary"
            :plain="!canNextStep"
            data-testid="next-button"
            data-name="next-button"
            @click="gotoStep(selectedStep + 1)">
            {{ $t('general.next') }}
            <i class="fas fa-arrow-circle-right"
              aria-hidden="true" />
          </el-button>
          <el-button v-else
            type="primary"
            data-testid="add-mve-button"
            @click="save">
            {{ $t(creating ? 'general.add-type' : 'general.update-type', { type: $t('productNames.mve') }) }}
            <i class="fas fa-check-circle"
              aria-hidden="true" />
          </el-button>
        </footer>
      </el-card>
    </section>
  </section>
</template>

<script>
// External tools
import { mapState, mapGetters, mapActions } from 'vuex'
import { set } from 'lodash'
import { v1 as uuid } from 'uuid'
import ipRegex from 'ip-regex'
import isValidDomain from 'is-valid-domain'
// Internal tools
import sdk from '@megaport/api-sdk'
import captureSentryError from '@/utils/CaptureSentryError.js'
import { passStrength } from '@/utils/passwordStrength.js'
import { encrypt } from '@/utils/Sha256Crypt'
import { validatePassword } from '@/validators.js'
import { capitalizeFirstOnly, deepClone } from '@/helpers.js'
import formatDealName from '@/filters/formatDealName'
import { resolveServicesPage } from '@/utils/MapDataUtils.js'
import { calculateAutoDiversity } from '@/utils/AutoDiversity'
import { scopedPriceBook } from '@/utils/priceBook.js'
// Components
import ProcessSteps from '@/components/ui-components/ProcessSteps.vue'
import EndDetailsCard from '@/components/ui-components/EndDetailsCard.vue'
import PriceBox from '@/components/ui-components/PriceBox.vue'
import ConfigureLocation from '@/components/mve/ConfigureLocation.vue'
import ConfigureMve from '@/components/mve/ConfigureMve.vue'
import ConfigureSummary from '@/components/mve/ConfigureSummary.vue'
// Integrations
import { captureEvent } from '@/utils/analyticUtils'
// Globals
import {
  // Diversity zone
  G_DIVERSITY_ZONE_AUTO,
  G_DIVERSITY_ZONE_RED,
  G_DIVERSITY_ZONE_BLUE,
  // Step statuses
  PROCESS_STEP_UNAVAILABLE,
  PROCESS_STEP_AVAILABLE,
  PROCESS_STEP_COMPLETE,
  // Vendor codes
  ARUBA_PRODUCT,
  AVIATRIX_PRODUCT,
  CISCO_c8000_PRODUCT,
  CISCO_FTDV_PRODUCT,
  FORTINET_PRODUCT,
  MERAKI_PRODUCT,
  PALO_ALTO_PRODUCT,
  PRISMA_3108_PRODUCT,
  PRISMA_7108_PRODUCT,
  SIX_WIND_PRODUCT,
  VERSA_PRODUCT,
  VMWARE_PRODUCT,
  // MVE
  G_PRODUCT_TYPE_MVE,
} from '@/Globals.js'
import { omit } from 'lodash'

export default {
  name: 'MVEDesign',

  components: {
    'process-steps': ProcessSteps,
    'end-details-card': EndDetailsCard,
    'price-box': PriceBox,
  },

  filters: {
    formatDealName,
  },

  inject: ['layout', 'disabledFeatures', 'isFeatureEnabled'],

  data() {
    return {
      selectedStep: 0,
      price: {
        monthlyRate: null,
        currency: null,
      },
      mveData: {
        term: 12, // Defaults to 12 months
        productUid: null,
        locationId: null,
        productName: null,
        dealUid: 'None',
        costCentre: null,
        vendorConfig: null,
        vnics: [],
        config: {
          diversityZone: G_DIVERSITY_ZONE_AUTO,
          custom_properties: {
            filename: '',
          },
        },
      },
      mveImageData: null,
      isValidVendorConfig: false,
      deals: [],
      fields: [
        {
          key: 'productName',
          path: 'productName',
          type: 'text',
          label: this.$t('general.type-name', { type: this.$t('productNames.mve') }),
          required: true,
          validations: [
            {
              validator: this.validators().validateRequiredField(this.$t('general.name')),
              trigger: ['blur', 'change'],
            },
          ],
          vendors: () => this.allVendors,
        },
        {
          key: 'productSize',
          path: 'vendorConfig.productSize',
          type: 'select',
          label: this.$t('general.size'),
          required: true,
          sizeInfo: () => {
            if (!this.mveSizeHasBeenSelected) return null

            if (!this.mveSizeInfoDetails.storage) {
              return this.$t('ports.unbundled-mve-size-info-no-storage', {
                cpuCount: this.mveSizeInfoDetails.cpuCount,
                ram: this.mveSizeInfoDetails.ram,
              })
            } else {
              return this.$t('ports.unbundled-mve-size-info', {
                cpuCount: this.mveSizeInfoDetails.cpuCount,
                ram: this.mveSizeInfoDetails.ram,
                storage: this.mveSizeInfoDetails.storage,
              })
            }
          },
          options: () => this.availableSizes,
          validations: [
            {
              validator: this.validators().validateRequiredField(this.$t('general.size')),
              trigger: ['change'],
            },
          ],
          formatter: value => {
            if (value) return capitalizeFirstOnly(value)
          },
          vendors: () => this.allVendors,
        },
        {
          key: 'dealUid',
          path: 'dealUid',
          type: 'select',
          label: this.$t('general.partner-deals'),
          options: () => this.deals,
          vendors: () => this.allVendors,
        },
        {
          key: 'costCentre',
          path: 'costCentre',
          type: 'text',
          label: this.$t('services.invoice-reference'),
          required: false,
          demo: '123456789',
          tooltip: this.$t('services.invoice-reference-explanation'),
          vendors: () => this.allVendors,
          validations: [
            {
              max: 255,
              message: this.$tc('validations.max-length', 255, { max: 255 }),
              trigger: ['change', 'blur'],
            },
          ],
        },
        {
          key: 'licenseData',
          path: 'vendorConfig.licenseData',
          type: 'text',
          label: this.$t('ports.mve-license-data'),
          required: false,
          demo: '987654321',
          validations: [
            {
              validator: this.validators().validatePaloAltoLicenseData(),
              trigger: ['blur', 'change'],
            },
          ],
          vendors: () => [PALO_ALTO_PRODUCT],
          checkMark: true,
        },
        {
          key: 'adminPassword',
          path: 'vendorConfig.adminPassword',
          type: 'password',
          label: this.$t('ports.mve-admin-password'),
          required: true,
          demo: 'aw3som3C0d3!',
          tooltip: this.$t('ports.mve-admin-password-tooltip'),
          validations: [
            {
              validator: this.validators().validateRequiredPassword(this.$t('ports.mve-admin-password')),
              trigger: ['blur', 'change'],
            },
          ],
          vendors: () => [CISCO_FTDV_PRODUCT, PALO_ALTO_PRODUCT],
          checkMark: true,
        },
        {
          key: 'manageLocally',
          path: 'vendorConfig.manageLocally',
          type: 'checkbox',
          label: this.$t('ports.mve-manage-locally'),
          tooltip: this.$t('ports.mve-manage-locally-tooltip'),
          vendors: () => [CISCO_FTDV_PRODUCT],
          checkMark: true,
        },
        {
          key: 'fmcIpAddress',
          path: 'vendorConfig.fmcIpAddress',
          type: 'text',
          label: this.$t('ports.mve-fmc-ip-address'),
          required: true,
          demo: '192.0.1.0',
          validations: [
            {
              validator: this.validators().validateAddress(this.$t('ports.mve-fmc-ip-address')),
              trigger: ['blur', 'change'],
            },
          ],
          tooltip: this.$t('ports.mve-fmc-ip-address-tooltip'),
          vendors: () => [...this.selectedManageLocally ? [] : [CISCO_FTDV_PRODUCT]],
        },
        {
          key: 'fmcRegistrationKey',
          path: 'vendorConfig.fmcRegistrationKey',
          type: 'text',
          label: this.$t('ports.mve-fmc-registration-key'),
          required: true,
          demo: 'fmcRegistrationKey',
          validations: [
            {
              validator: this.validators().validateRequiredField(this.$t('ports.mve-fmc-registration-key')),
              trigger: ['blur', 'change'],
            },
            {
              validator: this.validators().validateFmcFields(this.$t('ports.mve-fmc-registration-key')),
              trigger: ['blur', 'change'],
            },
          ],
          vendors: () => [...this.selectedManageLocally ? [] : [CISCO_FTDV_PRODUCT]],
        },
        {
          key: 'fmcNatId',
          path: 'vendorConfig.fmcNatId',
          type: 'text',
          label: this.$t('ports.mve-nat-id'),
          tooltip: this.$t('ports.mve-nat-id-tooltip'),
          required: false,
          demo: 'natId123',
          validations: [
            {
              validator: this.validators().validateFmcFields(this.$t('ports.mve-nat-id')),
              trigger: ['blur', 'change'],
            },
          ],
          vendors: () => [...this.selectedManageLocally ? [] : [CISCO_FTDV_PRODUCT]],
        },
        {
          key: 'applianceMode',
          path: 'vendorConfig.applianceMode',
          type: 'select',
          label: this.$t('ports.mve-appliance-mode'),
          required: true,
          options: () => [{ label: 'SD-WAN', value: 'SD-WAN' }, { label: 'Autonomous', value: 'AUTONOMOUS' }],
          validations: [
            {
              validator: this.validators().validateRequiredField(this.$t('ports.mve-appliance-mode')),
              trigger: ['change'],
            },
          ],
          tooltip: this.$t('ports.mve-appliance-mode-tooltip'),
          vendors: () => [CISCO_c8000_PRODUCT],
        },
        {
          key: 'cloudInit',
          path: 'vendorConfig.cloudInit',
          type: 'upload',
          label: this.$t('ports.mve-cloud-init-file-upload'),
          required: true,
          // Commented out until we get proper documentation on the cloud init file.
          // tooltip: this.$t('ports.mve-file-tooltip'),
          upload: {
            multiple: false,
            showFileList: false,
            drag: false,
            help: () => this.cloudInitFileHelpText,
            file: {
              key: 'chosenFile',
              path: 'vendorConfig.chosenFile',
            },
          },
          validations: [
            {
              validator: this.validators().validateRequiredField(this.$t('ports.mve-init')),
              trigger: ['change'],
            },
          ],
          vendors: () => [AVIATRIX_PRODUCT, ...this.selectedApplianceMode === 'SD-WAN' ? [CISCO_c8000_PRODUCT] : []],
          checkMark: true,
        },
        {
          key: 'licenseData',
          path: 'vendorConfig.licenseData',
          type: 'upload',
          label: this.$t('ports.mve-appliance-licence'),
          // Commented out until we get proper documentation on the cloud init file.
          // tooltip: this.$t('ports.mve-file-tooltip'),
          upload: {
            multiple: false,
            showFileList: false,
            drag: false,
            file: {
              key: 'chosenFile',
              path: 'vendorConfig.chosenFile',
            },
          },
          vendors: () => [FORTINET_PRODUCT],
          checkMark: true,
        },
        {
          key: 'vcoAddress',
          path: 'vendorConfig.vcoAddress',
          type: 'text',
          label: this.$t('ports.mve-address'),
          required: true,
          placeholder: this.$t('ports.mve-address-placeholder'),
          demo: 'www.mve.com',
          validations: [
            {
              validator: this.validators().validateAddress(this.$t('ports.mve-address')),
              trigger: ['blur', 'change'],
            },
          ],
          vendors: () => [VMWARE_PRODUCT],
        },
        {
          key: 'vcoActivationCode',
          path: 'vendorConfig.vcoActivationCode',
          type: 'text',
          label: this.$t('ports.mve-activation-code'),
          required: true,
          demo: '1527-9872-0984-6717',
          validations: [
            {
              validator: this.validators().validateRequiredField(this.$t('ports.mve-activation-code')),
              trigger: ['blur', 'change'],
            },
          ],
          vendors: () => [VMWARE_PRODUCT],
          checkMark: true,
        },
        {
          key: 'sshPublicKey',
          path: 'vendorConfig.sshPublicKey',
          type: 'textarea',
          label: this.$t('ports.ssh-key'),
          required: true,
          demo: `ssh-rsa AAAAB3NzaC1yc2EAAAABIwAAAQEAklOUpkDHrfHY17SbrmTIpNLTGK9Tjom/BWDSU
            GPl+nafzlHDTYW7hdI4yZ5ew18JH4JW9jbhUFrviQzM7xlELEVf4h9lFX5QVkbPppSwg0cda3
            Pbv7kOdJ/MTyBlWXFCR+HAo3FXRitBqxiX1nKhXpHAZsMciLq8V6RjsNAQwdsdMFvSlVK/7XA
            t3FaoJoAsncM1Q9x5+3V0Ww68/eIFmb1zuUFljQJKprrX88XypNDvjYNby6vw/Pb0rwert/En
            mZ+AW4OZPnTPI89ZPmVMLuayrD2cE86Z/il8b+gw3r3+1nKatmIkjn2so1d01QraTlMqVSsbx
            NrRFi9wrf+M7Q== schacon@mylaptop.local`,
          help: this.$t('ports.ssh-key-rsa-help'),
          validations: [
            {
              validator: this.validators().validateRequiredField(this.$t('ports.ssh-key')),
              trigger: ['blur', 'change'],
            },
          ],
          vendors: () => [
            ...this.selectedApplianceMode === 'AUTONOMOUS' ? [CISCO_c8000_PRODUCT] : [],
            FORTINET_PRODUCT,
            PALO_ALTO_PRODUCT,
            VMWARE_PRODUCT,
            SIX_WIND_PRODUCT,
          ],
          checkMark: true,
        },
        {
          key: 'directorAddress',
          path: 'vendorConfig.directorAddress',
          type: 'text',
          label: this.$t('ports.mve-director-address'),
          required: true,
          placeholder: this.$t('ports.mve-address-placeholder'),
          demo: 'director1.versa.com',
          validations: [
            {
              validator: this.validators().validateAddress(this.$t('ports.mve-director-address')),
              trigger: ['blur', 'change'],
            },
          ],
          vendors: () => [VERSA_PRODUCT],
        },
        {
          key: 'controllerAddress',
          path: 'vendorConfig.controllerAddress',
          type: 'text',
          label: this.$t('ports.mve-controller-address'),
          required: true,
          placeholder: this.$t('ports.mve-address-placeholder'),
          demo: 'controller1.versa.com',
          validations: [
            {
              validator: this.validators().validateAddress(this.$t('ports.mve-controller-address')),
              trigger: ['blur', 'change'],
            },
          ],
          vendors: () => [VERSA_PRODUCT],
        },
        {
          key: 'localAuth',
          path: 'vendorConfig.localAuth',
          type: 'text',
          label: this.$t('ports.mve-local-auth'),
          required: true,
          demo: 'SDWAN-Branch@Versa.com',
          tooltip: this.$t('ports.mve-local-auth-help'),
          validations: [
            {
              validator: this.validators().validateAuth(this.$t('ports.mve-local-auth')),
              trigger: ['blur', 'change'],
            },
            {
              max: 100,
              message: this.$tc('validations.max-length', 100, { max: 100 }),
              trigger: ['blur', 'change'],
            },
          ],
          vendors: () => [VERSA_PRODUCT],
        },
        {
          key: 'remoteAuth',
          path: 'vendorConfig.remoteAuth',
          type: 'text',
          label: this.$t('ports.mve-remote-auth'),
          required: true,
          demo: 'Controller-1-staging@Versa.com',
          tooltip: this.$t('ports.mve-remote-auth-help'),
          validations: [
            {
              validator: this.validators().validateAuth(this.$t('ports.mve-remote-auth')),
              trigger: ['blur', 'change'],
            },
            {
              max: 100,
              message: this.$tc('validations.max-length', 100, { max: 100 }),
              trigger: ['blur', 'change'],
            },
          ],
          vendors: () => [VERSA_PRODUCT],
        },
        {
          key: 'serialNumber',
          path: 'vendorConfig.serialNumber',
          type: 'text',
          label: this.$t('ports.serial-number'),
          required: true,
          demo: 'Megaport-Hub1',
          validations: [
            {
              validator: this.validators().validateAuth(this.$t('ports.serial-number')),
              trigger: ['blur', 'change'],
            },
            {
              max: 40,
              message: this.$tc('validations.max-length', 40, { max: 40 }),
              trigger: ['blur', 'change'],
            },
          ],
          vendors: () => [VERSA_PRODUCT],
        },
        {
          key: 'accountName',
          path: 'vendorConfig.accountName',
          type: 'text',
          label: this.$t('ports.aruba-orchestrator-name'),
          required: true,
          demo: 'My Aruba MVE connection',
          tooltip: this.$t('ports.aruba-orchestrator-name-tt'),
          validations: [
            {
              validator: this.validators().validateRequiredField(this.$t('ports.aruba-orchestrator-name')),
              trigger: ['blur', 'change'],
            },
          ],
          vendors: () => [ARUBA_PRODUCT],
        },
        {
          key: 'accountKey',
          path: 'vendorConfig.accountKey',
          type: 'text',
          label: this.$t('ports.aruba-account-key'),
          required: true,
          demo: '1234567890',
          tooltip: this.$t('ports.aruba-account-key-tt'),
          validations: [
            {
              validator: this.validators().validateRequiredField(this.$t('ports.aruba-account-key')),
              trigger: ['blur', 'change'],
            },
          ],
          vendors: () => [ARUBA_PRODUCT],
        },
        {
          key: 'ionKey',
          path: 'vendorConfig.ionKey',
          type: 'text',
          label: this.$t('ports.mve-ion-key'),
          required: true,
          demo: '1234567890-1abb92c7-ba8c-4e02-a59c-05473fb17e0e',
          validations: [
            {
              validator: this.validators().validateIonKey(this.$t('ports.mve-ion-key')),
              trigger: ['blur', 'change'],
            },
          ],
          vendors: () => [PRISMA_3108_PRODUCT, PRISMA_7108_PRODUCT],
        },
        {
          key: 'secretKey',
          path: 'vendorConfig.secretKey',
          type: 'text',
          label: this.$t('ports.mve-secret-key'),
          required: true,
          demo: 'abc123def456abc789def012abc345def678abc9',
          validations: [
            {
              validator: this.validators().validateRequiredField(this.$t('ports.mve-secret-key')),
              trigger: ['blur', 'change'],
            },
            {
              pattern: '^[0-9a-f]{40}$',
              message: this.$t('validations.invalid-field', { thing: this.$t('ports.mve-secret-key') }),
              trigger: ['blur', 'change'],
            },
          ],
          vendors: () => [PRISMA_3108_PRODUCT, PRISMA_7108_PRODUCT],
        },
        {
          key: 'systemTag',
          path: 'vendorConfig.systemTag',
          type: 'text',
          label: this.$t('ports.aruba-system-tag'),
          demo: 'Preconfiguration-aruba-test-1',
          tooltip: this.$t('ports.aruba-system-tag-tt'),
          vendors: () => [ARUBA_PRODUCT],
        },
        {
          key: 'token',
          path: 'vendorConfig.token',
          type: 'text',
          label: this.$t('general.token'),
          required: true,
          demo: 'token',
          validations: [
            {
              validator: this.validators().validateRequiredField(this.$t('general.token')),
              trigger: ['blur', 'change'],
            },
          ],
          vendors: () => [MERAKI_PRODUCT],
        },
        {
          key: 'vnics',
          path: 'vnics',
          type: 'multi-text',
          itemKey: 'description',
          label: this.$t('connections.virtual-interfaces-vnics'),
          validations: [
            {
              validator: this.validators().validateVNics(),
              trigger: ['blur', 'change'],
            },
          ],
          vendors: () => this.allVendors,
        },
        {
          key: 'term',
          path: 'term',
          label: this.$t('ports.minimum-term'),
          type: 'term',
          required: true,
          validations: [
            {
              validator: this.validators().validateRequiredField(this.$t('general.term')),
              trigger: ['change'],
            },
          ],
          vendors: () => this.allVendors,
        },
        {
          key: 'subscription',
          path: 'term',
          label: this.$t('partner-vantage.subscription'),
          type: 'term',
          required: true,
          validations: [
            {
              validator: this.validators().validateRequiredField(this.$t('partner-vantage.subscription')),
              trigger: ['change'],
            },
          ],
          vendors: () => this.allVendors,
        },
      ],
    }
  },

  computed: {
    ...mapState('Company', { companyUid: state => state.data.companyUid }),
    ...mapState('MVE', ['images']),
    ...mapGetters('Auth', ['hasFeatureFlag', 'isManagedAccount', 'isPartnerVantage']),
    ...mapGetters('Services', ['getLocationById']),
    ...mapGetters('MVE', ['getMVE', 'getImageForLocationAndImageId', 'getAvailableMveLocations']),

    creating() {
      return !this.$route.params.productUid
    },

    // Step-related computed properties
    steps() {
      const steps =
        [
          {
            title: this.$t('general.select-location'),
            status: PROCESS_STEP_AVAILABLE,
            component: ConfigureLocation,
            calculatePrice: true,
            showPriceBox: true,
          },
          {
            title: this.$t('general.configure'),
            status: PROCESS_STEP_UNAVAILABLE,
            component: ConfigureMve,
            calculatePrice: false,
            showPriceBox: false,
          },
          {
            title: this.$t('general.summary'),
            status: PROCESS_STEP_UNAVAILABLE,
            component: ConfigureSummary,
            calculatePrice: true,
            showPriceBox: false,
          },
        ]

      const [step1, step2, step3] = steps

      // Mark the first step as completed and the second one as available
      // once a location has been selected.
      if (this.mveData.locationId) {
        step1.status = PROCESS_STEP_COMPLETE
        step2.status = PROCESS_STEP_AVAILABLE
      }

      // Mark the second step as completed once all input datas has passed validation.
      if (step1.status === PROCESS_STEP_COMPLETE &&
        this.mveData.productName &&
        this.isValidVendorConfig) {
        step2.status = PROCESS_STEP_COMPLETE
      }

      // Since the third step is just a summary of the previous two,
      // mark it as completed once the first two are completed.
      if (step1.status === PROCESS_STEP_COMPLETE &&
        step2.status === PROCESS_STEP_COMPLETE
      ) {
        step3.status = PROCESS_STEP_COMPLETE
      }

      return steps
    },
    canNextStep() {
      return this.steps[this.selectedStep].status === PROCESS_STEP_COMPLETE
    },
    currentDetailComponent() {
      return this.steps[this.selectedStep].component
    },

    // Detail-related computed properties
    detailLines() {
      const detailLines = []
      const vendor = this.selectedVendor || '-'
      const size = this.selectedMveSize ? this.selectedMveLabel : '-'

      detailLines.push(this.mveData.productName || this.$t('general.untitled'))
      detailLines.push(`${this.$t('ports.vendor')}: ${vendor}, ${this.$t('general.size')}: ${size}`)
      detailLines.push(this.selectedLocation ? this.selectedLocation.formatted.short : this.$t('general.no-location'))

      return detailLines
    },
    mveSizeInfoDetails() {
      if (!this.mveSizeHasBeenSelected) return {}

      const selectedMveDetails = this.selectedMveVersion?.details
        .find(size => size.size === this.mveData?.vendorConfig?.productSize)

      const { cpuCoreCount, ramGB } = selectedMveDetails

      const infoDetails = {
        cpuCount: cpuCoreCount,
        ram: ramGB,
        storage: this.selectedVendorStorage,
      }

      return infoDetails
    },

    // User-selection-related computed properties
    selectedLocation() {
      return this.getLocationById(this.mveData.locationId)
    },
    selectedVendor() {
      return this.mveData.vendorConfig._vendor
    },
    selectedVendorCode() {
      return this.mveData.vendorConfig._productCode
    },
    selectedMveSize() {
      return this.mveData.vendorConfig.productSize
    },
    selectedMveVersion() {
      if (!this.mveImageData?.versions?.length) return
      return this.mveImageData?.versions.find(version => version.name === this.mveData.vendorConfig._version)
    },
    mveSizeHasBeenSelected() {
      return !!this.selectedMveSize
    },
    selectedMveLabel() {
      const selectedProductSizeData = this.selectedMveVersion?.details?.find(size => size.size === this.selectedMveSize)
      return selectedProductSizeData?.label
    },
    /**
     * Returns the storage value based on the selected vendor.
     * This is likely to be removed in the near future.
     */
    selectedVendorStorage() {
      if (this.selectedVendorCode) {
        switch (this.selectedVendorCode) {
          case ARUBA_PRODUCT:
            return '30'
          case VERSA_PRODUCT:
            return '80'
          case AVIATRIX_PRODUCT:
            return '64'
          case PALO_ALTO_PRODUCT:
          case PRISMA_3108_PRODUCT:
          case PRISMA_7108_PRODUCT:
            return '60'
          case CISCO_c8000_PRODUCT:
          case CISCO_FTDV_PRODUCT:
          case FORTINET_PRODUCT:
          case VMWARE_PRODUCT:
            return '8'
          // Netauto assigns storage based on requirement of image being deployed
          case MERAKI_PRODUCT:
          case SIX_WIND_PRODUCT:
            return ''
          default:
            throw new Error('Unrecognised vendor')
        }
      } else {
        return null
      }
    },

    /** Fields rendered based on vendor selection and customer type/permissions */
    filteredFields() {
      let finalFields = []

      // If no vendor has been selected, return an empty array.
      // This is used to conditionally mount MveVendorsForm in ConfigureMve.
      if (!this.selectedVendorCode) {
        return finalFields
      }

      finalFields = this.fields.filter(f => {
        // Filter the deal field as per show deal
        if (f.key === 'dealUid' && !this.showDeals) return false
        if (f.key === 'term' && this.isPartnerVantage) return false
        if (f.key === 'subscription' && !this.isPartnerVantage) return false

        if (f.key === 'vnics') {
          // Default max number of vNics for all vendors
          f.maxItems = 5

          switch (this.selectedVendorCode) {
            // Meraki has a maximum of 1 vNIC
            case MERAKI_PRODUCT:
              f.maxItems = 1
              break
            // Palo alto has a minimum of 2 vNICs
            case PALO_ALTO_PRODUCT:
              f.minimumItems = 2
              break
            // Aviatrix/Prisma 7108v has a minimum of 3 vNICs
            case AVIATRIX_PRODUCT:
            case PRISMA_7108_PRODUCT:
              f.minimumItems = 3
              break
            // Cisco firewall/Prisma 3108v has a minimum of 4 vNICs
            case CISCO_FTDV_PRODUCT:
            case PRISMA_3108_PRODUCT:
              f.minimumItems = 4
              break
            default:
              f.minimumItems = 1
              break
          }
        }

        return (!f.vendors || f.vendors().includes(this.selectedVendorCode))
      })

      return finalFields
    },

    // Further availability/customer-type/permissions-related computed properties
    availableSizes() {
      return this.getAvailableSizes(this.ids.location, this.ids.image)
    },
    calculatePrice() {
      return !this.disabledFeatures.showPrices && this.isFeatureEnabled('PRICING_VISIBLE') && this.steps[this.selectedStep].calculatePrice
    },
    showPriceBox() {
      return this.calculatePrice && this.steps[this.selectedStep].showPriceBox
    },
    showDeals() {
      // Hide if the vantage partner & vantage managed account flags aren't present
      if (!this.isFeatureEnabled('VANTAGE_PARTNER') && !this.isFeatureEnabled('VANTAGE_MANAGED_ACCOUNT')) {
        return false
      }
      // Show if managed account
      return this.isManagedAccount
    },

    /** All relevant IDs */
    ids() {
      return {
        company: this.companyUid,
        location: this.mveData.locationId,
        product: this.mveData.productUid,
        image: this.mveData.vendorConfig.imageId,
        deal: this.mveData.dealUid,
      }
    },
    selectedApplianceMode() {
      return this.mveData.vendorConfig.applianceMode
    },
    selectedManageLocally() {
      return this.mveData.vendorConfig.manageLocally
    },
    /** Returns an array containing the names of all available vendors */
    allVendors() {
      return [
        ARUBA_PRODUCT,
        AVIATRIX_PRODUCT,
        CISCO_c8000_PRODUCT,
        CISCO_FTDV_PRODUCT,
        FORTINET_PRODUCT,
        MERAKI_PRODUCT,
        PALO_ALTO_PRODUCT,
        PRISMA_3108_PRODUCT,
        PRISMA_7108_PRODUCT,
        SIX_WIND_PRODUCT,
        VERSA_PRODUCT,
        VMWARE_PRODUCT,
      ]
    },
    cloudInitFileHelpText() {
      return this.$t('ports.mve-cloud-init-file-upload-help', {
        title: this.selectedVendorCode === AVIATRIX_PRODUCT
          ? this.$t('ports.mve-aviatrix-secure-edge')
          : this.$t('ports.mve-cisco-vmanage'),
      })
    },
  },

  watch: {
    /**
     * Watch for changes in the selected vendor and update the default vnics array depending on selected vendor.
     */
    'mveData.vendorConfig._productCode': {
      handler(newVendor, oldVendor) {
        // Only care if a vendor is selected.
        if (!newVendor) return

        // Set vnic defaults for vendor, depending on the chosen vendor.
        const vendorVnics = {
          [PALO_ALTO_PRODUCT]: [
            { description: 'Management Plane' },
            { description: 'Data Plane' },
          ],
          [AVIATRIX_PRODUCT]: [
            { description: 'eth0' },
            { description: 'eth1' },
            { description: 'eth2' },
          ],
          [PRISMA_7108_PRODUCT]: [
            { description: 'Controller port' },
            { description: 'Port 1' },
            { description: 'Port 2' },
          ],
          [PRISMA_3108_PRODUCT]: [
            { description: 'Controller port' },
            { description: 'Port 1' },
            { description: 'Port 2' },
            { description: 'Port 3' },
          ],
          [CISCO_FTDV_PRODUCT]: [
            { description: 'Management' },
            { description: 'Diagnostic' },
            { description: 'Data1' },
            { description: 'Data2' },
          ],
        }

        const oldVendorHasMultipleVnics = [
          AVIATRIX_PRODUCT,
          CISCO_FTDV_PRODUCT,
          PALO_ALTO_PRODUCT,
          PRISMA_3108_PRODUCT,
          PRISMA_7108_PRODUCT,
        ].includes(oldVendor)

        if (vendorVnics[newVendor] && (this.creating || oldVendor)) {
          this.mveData.vnics = vendorVnics[newVendor]
        } else if (this.mveData.vnics.length === 0 || oldVendorHasMultipleVnics) {
          // All other vendors have a single default vNic
          this.mveData.vnics = [{ description: 'Data Plane' }]
        }
      },
    },
    calculatePrice(calculate) {
      if (calculate) this.updatePrice()
    },
  },

  created() {
    // Fetch images if they haven't been fetched yet
    if (!this.images.length) this.fetchImages()

    this.createDefaultVendorConfig()
    this.fetchDeals()

    if (this.$route.query.locationId) {
      this.mveData.locationId = parseInt(this.$route.query.locationId)
      this.$router.replace({ query: {} })
    }
  },

  mounted() {
    this.creating ? this.mveData.productUid = uuid() : this.setUpForInDesignService()
  },

  beforeUpdate() {
    // Making sure this block runs only once per edit, tackling potential efficiency issues.
    if (!this.mveImageData && this.ids.location && this.ids.image) {
      this.mveImageData = this.getImageForLocationAndImageId(this.ids.location, this.ids.image)
    }
  },

  methods: {
    ...mapActions('MVE', ['fetchImages', 'saveDesign']),

    resolveServicesPage,
    scopedPriceBook,

    setUpForInDesignService() {
      // Remove the VUEX Reactivity
      const mve = deepClone(this.getMVE(this.$route.params.productUid))

      const {
        locationId = null,
        productName = null,
        term = null,
        dealUid = null,
        costCentre = null,
        vendorConfig = null,
        vnics = null,
        config = null,
      } = mve

      this.mveData = {
        ...this.mveData,
        term,
        locationId,
        productName,
        dealUid,
        costCentre,
        vendorConfig,
        config,
        vnics,
        productUid: this.$route.params.productUid,
      }

      this.isValidVendorConfig = true

      if (this.calculatePrice) this.updatePrice()
    },
    /**
     * Get the available sizes for a given location and image ID.
     * @param {number} locationId Location ID
     * @param {number} imageId Image ID
     */
    getAvailableSizes(locationId, imageId) {
      if (locationId && imageId) {
        const imageInfo = this.getImageForLocationAndImageId(locationId, imageId)

        if (!imageInfo?.details) return []

        const { details } = imageInfo
        const mveSizeData = details.map(detail => ({
          label: detail.label,
          value: detail.size,
        }))

        return mveSizeData
      }
    },
    updateImageData(image) {
      this.isValidVendorConfig = false
      this.mveImageData = image
    },
    handleValidation(isValid) {
      this.isValidVendorConfig = isValid
    },
    createDefaultVendorConfig() {
      this.mveData = {
        ...this.mveData,
        productName: '',
        vendorConfig: {
          imageId: null,
          productSize: null,
          applianceMode: null,
          manageLocally: false,
          // The API does not expect these fields to be sent so private them with an underscore
          _vendor: null,
          _productCode: null,
          _version: null,
        },
      }
    },
    fetchDeals() {
      let defaultDeal = {
        entityUid: 'None',
        value: 'None',
        label: 'None',
        opportunityName: 'None',
        dealId: '',
      }
      sdk.instance
        .partnerVantage()
        .deals(this.companyUid)
        .then(res => {
          if (res.length) {
            this.deals = [
              defaultDeal,
              ...res,
            ]
            // Add label/value keys to suit the form builder select type
            this.deals = this.deals.map(deal => ({
              ...deal,
              label: formatDealName(deal),
              value: deal.entityUid,
            }))
            // Sort in ascending order by deal id
            this.deals = this.deals.sort((a, b) => a.dealId.toUpperCase().localeCompare(b.dealId.toUpperCase()))
          } else {
            this.deals = [defaultDeal]
          }
        })
        .catch(e => captureSentryError(e))
    },
    validateCurrentStep() {
      this.$refs.currentDetailComponent.validate && this.$refs.currentDetailComponent.validate()
    },
    validators() {
      const validateVNics = () => (_rule, value, callback) => {
        const errors = value.reduce((accum, vnic, index) => {
          if (!vnic.description) accum[index] = this.$t('validations.required', { thing: 'vNIC description' })
          return accum
        }, {})

        if (Object.keys(errors).length > 0) return callback(errors)

        callback()
      }

      const validateAddress = label => (_rule, value, callback) => {
        if (!value) {
          callback(new Error(this.$t('validations.required', { thing: label })))
        } else if (!(ipRegex({ exact: true }).test(value) || isValidDomain(value))) { // Accept IPv4/IPv6 IP addresses or domain names
          callback(new Error(this.$t('validations.invalid-address-domain')))
        } else {
          callback()
        }
      }

      const validateAuth = label => (_rule, value, callback) => {
        if (!value) {
          callback(new Error(this.$t('validations.required', { thing: label })))
        } else if (!value.match(/^[0-9a-zA-Z!@#?.$%^&*+=-]+$/g)) {
          callback(new Error(this.$t('validations.enter-valid-auth-key', { thing: label.toLowerCase() })))
        } else {
          callback()
        }
      }

      const validateRequiredField = label => (_rule, value, callback) => {
        if (!value) {
          callback(new Error(this.$t('validations.required', { thing: label })))
        } else {
          callback()
        }
      }

      const validateRequiredPassword = label => (_rule, value, callback) => {
        if (!value) {
          callback(new Error(this.$t('validations.required', { thing: label })))
        } else {
          // Check whether password is strong enough
          const passwordStrengthAnalysis = passStrength(value)
          // Check the password meets the complexity requirements taking its strength into account
          validatePassword(_rule, value, callback, passwordStrengthAnalysis)
        }
      }

      const validatePaloAltoLicenseData = () => (_rule, value, callback) => {
        // This field is not required so throw no errors if empty
        if (!value) {
          callback()
        } else if (!value.match(/^[a-zA-Z0-9]*$/)) { // Accept only alphanumeric characters
          callback(new Error(this.$t('validations.alphanumeric', { thing: this.$t('ports.mve-license-data') })))
        } else if (value.length < 8) { // Palo Alto License Data must be at least eight characters long
          callback(new Error(this.$tc('validations.min-length', 8, { min: 8 })))
        } else if (value.length > 9) { // Palo Alto License Data must be under ten characters long
          callback(new Error(this.$tc('validations.max-length', 9, { max: 9 })))
        } else {
          callback()
        }
      }

      const validateFmcFields = label => (_rule, value, callback) => {
        if (!value) {
          callback()
        } else if (!value.match(/^[a-zA-Z0-9]*$/)) { // Accept only alphanumeric characters
          callback(new Error(this.$t('validations.alphanumeric', { thing: label })))
        } else if (value.length > 37) { // Field value has max length of 37 characters long
          callback(new Error(this.$tc('validations.max-length', 37, { max: 37 })))
        } else {
          callback()
        }
      }

      const validateIonKey = label => (_rule, value, callback) => {
        // ION key is a UUID v4 prefixed with a TenantID which is always numeric with a max length of 20
        const ionKeyRegex = /^\d{1,20}-[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/

        if (!value) {
          callback(new Error(this.$t('validations.required', { thing: label })))
        } else if (!value.match(ionKeyRegex)) {
          callback(new Error(this.$t('validations.invalid-field', { thing: label })))
        } else {
          callback()
        }
      }

      return {
        validateVNics,
        validateAddress,
        validateAuth,
        validateRequiredField,
        validateRequiredPassword,
        validatePaloAltoLicenseData,
        validateFmcFields,
        validateIonKey,
      }
    },
    /**
     * Go to a specific step in the MVE creation process.
     * @param {number} index Index of the step to go to
     */
    gotoStep(index) {
      this.trackButtonClick(index > this.selectedStep ? 'next' : 'back')

      // Remove focus from Next buttons
      const nextButtons = document.querySelectorAll('button[data-name="next-button"]')

      for (const button of nextButtons) {
        button.blur()
      }

      // If the current step is incomplete, then trigger an update of its visual validation
      // status. However, if the step we are trying to go to is before the current step, then we
      // bypass the validation of the step since we're going back.
      if (this.steps[this.selectedStep].status === PROCESS_STEP_AVAILABLE && index >= this.selectedStep) {
        this.validateCurrentStep()
      }

      // Only go to the requested step if available
      if (this.steps[index].status === PROCESS_STEP_UNAVAILABLE) return false

      // Calculate MVE diversity zone if applicable
      if (this.selectedStep === 0) this.calculateDiversity()

      this.selectedStep = index
    },
    calculateDiversity() {
      if (![G_DIVERSITY_ZONE_RED, G_DIVERSITY_ZONE_BLUE].includes(this.mveData.config.diversityZone)) {
        const selectedLocation = this.getAvailableMveLocations.find(location => location.id === this.mveData.locationId)
        this.mveData.config.diversityZone = calculateAutoDiversity(G_PRODUCT_TYPE_MVE, selectedLocation.diversityZones)
      }
    },
    /**
     * Update the MVE data object at the given key path with the new value
     * @param {string} keyPath THe key path to update
     * @param value The new value to set
     */
    updateData({ keyPath, value }) {
      // If the vendor has changed, reload the default config.
      if (keyPath === 'vendorConfig._vendor') {
        this.createDefaultVendorConfig()
      }

      if (keyPath === 'vendorConfig.chosenFile') {
        set(this.mveData, 'config.custom_properties.filename', value)
      }

      // Validate the MVE configuration form whenever the Appliance Mode changes
      // and we have relevant data stored for the selected mode
      if (keyPath === 'vendorConfig.applianceMode') {
        // When Autonomous mode has been selected and we have an SSH key stored for the service
        if (value === 'AUTONOMOUS' && this.mveData.vendorConfig.sshPublicKey) this.triggerDelayedValidation()
        // When SD-WAN mode has been selected and we have a cloud initialisation file stored for the service
        if (value === 'SD-WAN' && this.mveData.vendorConfig.cloudInit) this.triggerDelayedValidation()
      }

      // Standard updating of the MVE data
      set(this.mveData, keyPath, value)

      // Since both productSize and mveLabel change on the selection of the size for the MVE,
      // we first set the productSize via the standard way and then set the mveLabel next.
      if (keyPath === 'vendorConfig.productSize' && this.selectedMveLabel) {
        set(this.mveData, 'vendorConfig.mveLabel', this.selectedMveLabel)
      }

      if (this.calculatePrice) this.updatePrice()
    },
    async updatePrice() {
      if (this.selectedLocation &&
        this.selectedVendor &&
        this.selectedMveSize) {
        try {
          const price = await this.scopedPriceBook()
            .mve(
              this.mveData.locationId,
              this.mveData.vendorConfig._vendor,
              this.mveData.vendorConfig.productSize,
              this.mveData.productUid,
              this.mveData.term
            )
          this.price.monthlyRate = price.monthlyRate
          this.price.currency = price.currency
        } catch (error) {
          // Error handled by the pricebook module.
          this.price.monthlyRate = null
          this.price.currency = null
        }
      } else {
        this.price.monthlyRate = null
        this.price.currency = null
      }
    },
    save() {
      // Delete the default Appliance Mode field from the vendor configuration for non-Cisco c8000 MVEs
      if (this.selectedVendorCode !== CISCO_c8000_PRODUCT) delete this.mveData.vendorConfig.applianceMode

      // Clean up the vendor configuration for Cisco c8000 MVEs before saving it
      if (this.selectedVendorCode === CISCO_c8000_PRODUCT) {
        // Delete the fields relevant to an Autonomous Appliance Mode when the selected mode is SD-WAN
        if (this.mveData.vendorConfig.applianceMode === 'SD-WAN') delete this.mveData.vendorConfig.sshPublicKey

        // Delete the fields relevant to an SD-WAN Appliance Mode when the selected mode is Autonomous
        if (this.mveData.vendorConfig.applianceMode === 'AUTONOMOUS') {
          delete this.mveData.vendorConfig.cloudInit
          delete this.mveData.vendorConfig.chosenFile
          delete this.mveData.config.custom_properties.filename
        }
      }

      // Clean up the vendor configuration for Cisco FTDv MVEs before saving it
      if (this.selectedVendorCode === CISCO_FTDV_PRODUCT) {
        // Delete the hidden field's data when manage locally is selected
        if (this.mveData.vendorConfig.manageLocally) {
          delete this.mveData.vendorConfig.fmcIpAddress
          delete this.mveData.vendorConfig.fmcRegistrationKey
          delete this.mveData.vendorConfig.fmcNatId
        }
      } else {
        // Delete the manageLocally field when it is not relevant to the selected vendor
        delete this.mveData.vendorConfig.manageLocally
      }

      // If the chosen vendor is Palo Alto, we need to encrypt the MVE Admin Password
      // entered by the user before continuing.
      if (this.selectedVendorCode === PALO_ALTO_PRODUCT) {
        // Encrypt Admin Password entered by the user
        const encryptedAdminPassword = encrypt(this.mveData.vendorConfig.adminPassword)
        // Delete the field containing the raw password
        delete this.mveData.vendorConfig.adminPassword
        // Set the freshly encrypted password as our new Admin Password.
        // Note that we will name this field adminPasswordHash
        // even though the password is more than hashed for consistency with our APIs.
        set(this.mveData, 'vendorConfig.adminPasswordHash', encryptedAdminPassword)

        // If the user entered and then deleted a License Data value for a Palo Alto MVE,
        // we need to remove this field from our configuration since, when included,
        // it is validated by the API and an empty string would fail validation.
        if (this.mveData.vendorConfig.licenseData === '') delete this.mveData.vendorConfig.licenseData
      }

      // Save the service design in our store. This also adds the buyoutPort,
      // marketplaceVisibility, and virtual fields to the service.
      this.saveDesign(this.mveData)

      // Track the config-port event
      this.trackButtonClick('finish')

      // Notify the user their configuration has been successfully saved
      const props = {
        title: this.$t('general.success'),
        message: this.$t('services.saved'),
        duration: 4000,
      }
      this.$notify.success(props)

      // If we don't have the concept of configured services, then we skip the whole aside and
      // push to services part, and go directly to deployment.
      if (this.disabledFeatures.configuredServices) {
        this.$root.$emit('showOrderPanel')
      } else {
        this.$router.push(`/services${this.creating ? `?new_mve=${this.mveData.productUid}` : ''}`)
        this.$root.$emit('showActionAside')
      }
    },
    async triggerDelayedValidation() {
      await this.$nextTick()
      this.validateCurrentStep()
    },
    trackButtonClick(buttonName) {
      let stepName
      switch (this.selectedStep) {
        case 0:
          stepName = 'location-select'
          break
        case 1:
          stepName = 'config'
          break
        case 2:
          stepName = 'summary'
          break
        default:
          stepName = 'unknown'
      }
      captureEvent(`create-mve.${stepName}.${buttonName}.click`, buttonName === 'next' ? {
        ...omit(this.mveData, ['config', 'vendorConfig']),
        diversityZone: this.mveData.config.diversityZone,
        productSize: this.selectedMveSize,
      } : undefined)
    },
  },
}
</script>
