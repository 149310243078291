<template>
  <summary-content :heading="$t('general.summary')"
    :summary-items="summaryItems"
    :show-monthly-rate="showMonthlyRate"
    :monthly-rate="monthlyRate">
    <template #footer>
      <p class="mve-vnic-prompt-info">
        <em><strong>{{ $t('general.note') }}</strong>: {{ $t('ports.mve-vnic-prompt-info') }} </em>
      </p>
    </template>
  </summary-content>
</template>

<script>
// External tools
import { mapGetters } from 'vuex'
import { get } from 'lodash'
// Internal tools
import { moneyFilter } from '@/helpers.js'
import { scopedPriceBook } from '@/utils/priceBook.js'
// Components
import SummaryContent from '@/components/ui-components/SummaryContent.vue'
// Globals
import { PALO_ALTO_VENDOR } from '@/Globals.js'

export default {
  name: 'MveConfigureSummary',

  components: {
    'summary-content': SummaryContent,
  },

  inject: ['disabledFeatures', 'isFeatureEnabled'],

  props: {
    currentData: {
      type: Object,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    mveSizeLabel: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      monthlyRate: null,
      PALO_ALTO_VENDOR,
    }
  },

  computed: {
    ...mapGetters('Services', ['getLocationById']),
    ...mapGetters('MVE', ['getImageForLocationAndImageId']),
    summaryItems() {
      const imageInfoItem = { label: this.$t('ports.vendor'), value: this.getimageInfo }
      const locationInfoItem = [
        { label: this.$t('general.location'), value: this.selectedLocation },
        { label: this.$t('services.diversity-zone'), value: this.currentData.config.diversityZone },
      ]
      return [...locationInfoItem, imageInfoItem, ...this.fieldUpdate]
    },
    getimageInfo() {
      const imageInfo = this.getImageForLocationAndImageId(this.currentData.locationId, this.currentData.vendorConfig.imageId)
      return `${imageInfo.vendor} ${imageInfo.product} ${imageInfo.version}`
    },
    selectedLocation() {
      return this.getLocationById(this.currentData.locationId).formatted.short
    },
    fieldUpdate() {
      return this.fields.map(({ key, label, path, checkMark = false, formatter }) => {
        let value = formatter ? formatter(get(this.currentData, path)) : get(this.currentData, path)

        // custom option label for terms/subscriptions
        if (path === 'term') {
          value = value === 1
            ? this.$t(key === 'subscription' ? 'partner-vantage.no-subscription' : 'port-term.no-minimum-term')
            : this.$tc('general.count-months', value, { count: value })
        }

        // custom value for size
        if (key === 'productSize') {
          value = this.mveSizeLabel
        }

        // update vnics to correct format { label, value } for display on the summary
        if (key === 'vnics') {
          value = value.map((vnic, index) => ({ label: `vNIC-${index}`, value: vnic.description }))
        }

        return {
          label,
          value,
          checkMark,
        }
      })
        .filter(item => !!item.value)
    },
    showMonthlyRate() {
      return !this.disabledFeatures.showPrices && this.isFeatureEnabled('PRICING_VISIBLE')
    },
    vendor() {
      return this.currentData.vendorConfig._vendor
    },
  },

  mounted() {
    this.showMonthlyRate && this.readPrice()
  },

  methods: {
    scopedPriceBook,

    async readPrice() {
      try {
        const price = await this.scopedPriceBook()
          .mve(
            this.currentData.locationId,
            this.currentData.vendorConfig._vendor,
            this.currentData.vendorConfig.productSize,
            this.currentData.productUid,
            this.currentData.term
          )
        if (price) {
          this.monthlyRate = moneyFilter(price.monthlyRate, price.currency)
        } else {
          this.monthlyRate = null
        }
      } catch (error) {
        // Error handled by the pricebook module.
        this.monthlyRate = null
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.mve-vnic-prompt-info {
  margin-block-start: 2rem;
  padding-inline: 0.8rem;
  line-height: initial;
  font-size: 1.3rem;
}
</style>
